<template>
  <div>
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-12">
            <h1 class="m-0">RemotePay Management</h1>
          </div>
        </div>
      </div>
    </section>
    <div class="loading" v-if="loading">
      <hour-glass></hour-glass>
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card card-outline card-success">
              <div class="card-header">
                <h3 class="card-title">Total Transaction To Expire</h3>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-4 col-sm-6 col-12">
                    <div class="info-box mb-3 bg-danger expire-box" @click="viewStoreWiseTransactionExpireCount(0)">
                      <span class="info-box-icon"><i class="fas fa-calendar-alt"></i></span>
                      <div class="info-box-content">
                        <span class="info-box-text" style="width:50%; float:left;">{{showDate(0)}}</span><span class="text-right" style="width:50%; float:right; font-weight:bold;">Today</span>
                        <span class="info-box-number">#{{expired_count_today}}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 col-sm-6 col-12">
                    <div class="info-box mb-3 bg-amber expire-box" @click="viewStoreWiseTransactionExpireCount(1)">
                      <span class="info-box-icon"><i class="fas fa-calendar-alt"></i></span>
                      <div class="info-box-content">
                        <span class="info-box-text" style="width:50%; float:left;">{{showDate(1)}}</span><span class="text-right" style="width:50%; float:right; font-weight:bold;">Tomorrow</span>
                        <span class="info-box-number">#{{expired_count_tomorrow}}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 col-sm-6 col-12">
                    <div class="info-box mb-3 bg-warning expire-box" @click="viewStoreWiseTransactionExpireCount(2)">
                      <span class="info-box-icon"><i class="fas fa-calendar-alt"></i></span>
                      <div class="info-box-content">
                        <span class="info-box-text" style="width:50%; float:left;">{{showDate(2)}}</span><span class="text-right" style="width:50%; float:right; font-weight:bold;">Day After Tomorrow</span>
                        <span class="info-box-number">#{{expired_count_dayaftertomorrow}}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="card card-success">
              <div class="card-header">
                <h3 class="card-title">Search</h3>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-3">
                    <div class="form-group">
                      <multiselect
                        id="store"
                        name="store"
                        v-model="selectedStore"
                        placeholder="Select Store"
                        label="retailer"
                        track-by="id"
                        :options="storeList"
                        :multiple="false"
                        :searchable="true"
                        :show-labels="false"
                        ></multiselect>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <input
                        class="start-date form-control"
                        placeholder="Start Date"
                        id="start-date"
                        onkeydown="return false"
                        autocomplete="off"
                        />
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <input
                        class="end-date form-control"
                        placeholder="End Date"
                        id="end-date"
                        onkeydown="return false"
                        autocomplete="off"
                        />
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <button type="button" class="btn btn-success mr-3" @click="generateReport()"> Search </button>
                      <button type="button" class="btn btn-info mr-3" @click="generateReport()"> <i class="fas fa-redo"></i> </button>
                      <button type="button" class="btn btn-dark" @click="generateReportReset()"> Reset </button>
                    </div>
                  </div>
                </div>
                
                <div class="row">
                <div class="col-md-3">
                    <div class="form-group">
                      <button type="button" class="btn btn-link" @click="showAdvanceSearchModal()"> Advanced Search </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="card" style="height: 400px;">
              <div class="card-header border-0">
                <div class="row mb-3">
                  <div class="col-4 d-flex align-items-center"> 
                    <h3 class="card-title mb-0" style="font-size: 16px;">Transaction Pending</h3>
                  </div>
                </div>
                <div>
                  <a v-if="pending_and_unpaid_transactions.length > 4" href="javascript:void(0)" class="btn btn-sm btn-primary float-right" @click="viewmoreTransactions('Pending And Unpaid')">View more</a>
                </div>
              </div>
              <div  class="card-body table-responsive p-0 text-center no-record-found"  v-if="!pending_and_unpaid_transactions.length">
                No Record Found
              </div>
              <div class="card-body p-0 table-responsive" v-else>
                <table class="table table-striped table-valign-middle">
                  <thead>
                    <tr>
                      <th class="text-center">Transaction Number</th>
                      <th class="text-center">Transaction Date</th>
                      <th class="text-center">Settlement Date</th>
                      <th class="text-center">Amount</th>
                      <th class="text-center">Tip Amount</th>
                      <th class="text-center">Delivery Fee</th>
                      <th class="text-center">Expiration Date</th>
                      <th class="text-center">Status</th>
                      <th class="text-center">Action</th>
                      <th v-if="is_ecommerce_admin_driven==1" class="text-center">Book Transaction</th>
                    </tr>
                  </thead>
                  <tbody v-for="(row, index) in pending_and_unpaid_transactions" :key="index">
                    <tr v-if="index < 4">
                      <td class="text-center">
                        {{row.transaction_number}}
                      </td>
                      <td class="text-center">{{row.transaction_date}}</td>
                      <td class="text-center">{{row.scheduled_posting_date}}</td>
                      <td class="text-center">
                        <small class="text-success mr-1" v-if="row.updated_amount && row.updated_amount > row.orginal_amount">
                        ${{row.updated_amount.toFixed(2)}}
                        <i class="fas fa-arrow-up"></i>
                        </small>
                        <small class="text-danger mr-1" v-else-if="row.updated_amount && row.updated_amount < row.orginal_amount">
                        ${{row.updated_amount.toFixed(2)}}
                        <i class="fas fa-arrow-down"></i>
                        </small>
                        <small v-else>
                        ${{row.orginal_amount.toFixed(2)}}
                        </small>
                      </td>
                      <td class="text-center">
                        <small class="text-success mr-1" v-if="row.last_updated_tip_amount > row.orginal_tip_amount">
                        ${{row.last_updated_tip_amount.toFixed(2)}}
                        <i class="fas fa-arrow-up"></i>
                        </small>
                        <small class="text-danger mr-1" v-else-if="row.last_updated_tip_amount < row.orginal_tip_amount">
                        ${{row.last_updated_tip_amount.toFixed(2)}}
                        <i class="fas fa-arrow-down"></i>
                        </small>
                        <small v-else>
                        ${{row.last_updated_tip_amount.toFixed(2)}}
                        </small>
                      </td>
                      <td class="text-center">
                        <small>
                        ${{row.delivery_fee}}
                        </small>
                      </td>
                      <td class="text-center">{{row.expiry_date_for_book_now}}</td>
                      <td class="text-center">
                        {{row.status}}
                      </td>
                      <td class="text-center">
                        <button type="button" class="btn btn-success ecommerce-leave-modify" v-if="row.consumer_approval_for_change_request == 0 && (current_date <= row.expiry_date_for_book_now || row.expiry_date_for_book_now == '')" style="color: white !important; margin-bottom: 2px;"  @click="ecommerceModifyLeaveComment($event)" :data-tr-number="row.transaction_number" :data-tr-amount="row.last_approve_amount" :data-tr-tip-amount="row.last_approve_tip_amount" :data-tr-delivery_fee="row.delivery_fee" :data-id="row.edit" :data-tran-status="row.status"><i class="fa fa-edit" aria-hidden="true"></i> Modify </button>
                        <!-- line gap -->
                        <br v-if="row.consumer_approval_for_change_request == 0">
                        <!-- if expiration date -->
                        <button type="button" class="btn btn-danger ecommerce-leave-comment" v-if="row.cancelable == 1 && row.is_v1 == 0 && (current_date <= row.expiry_date_for_book_now || row.expiry_date_for_book_now == '')" style="color: white !important; margin-bottom: 2px;" :data-id="row.edit"><i class="fa fa-times fa-lg"></i> Void </button>
                      </td>
                      <td v-if="is_ecommerce_admin_driven==1" class="text-center">
                        <button type="button" @click="postToBankModel(row.edit)" class="btn btn-primary accept-payment" v-if="row.post_to_bank == 1 && current_date <= row.expiry_date_for_book_now" style="color: white !important; margin-bottom: 2px;" :data-id="row.edit"><i class="fa fa-edit" aria-hidden="true"></i> Accept Payment </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="card" style="height: 400px;">
              <div class="card-header border-0">
                <div class="row mb-3">
                  <div class="col-5 d-flex align-items-center"> 
                    <h3 class="card-title mb-0" style="font-size: 16px;">Consumer Approval Awaiting</h3>
                  </div>
                </div>
                <div>
                  <a v-if="awaiting_consumer_approval_transactions.length > 4" href="javascript:void(0)" class="btn btn-sm btn-primary float-right" @click="viewmoreTransactions('Awaiting Consumer Approval')">View more</a>
                </div>
              </div>
              <div  class="card-body table-responsive p-0 text-center no-record-found"  v-if="!awaiting_consumer_approval_transactions.length">
                No Record Found
              </div>
              <div class="card-body table-responsive p-0" v-else>
                <table class="table table-striped table-valign-middle">
                  <thead>
                    <tr>
                      <th class="text-center">Transaction Number</th>
                      <th class="text-center">Transaction Date</th>
                      <th class="text-center">Amount</th>
                      <th class="text-center">Tip Amount</th>
                      <th class="text-center">Delivery Fee</th>
                      <th class="text-center">Time Left</th>
                      <th class="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody  v-for="(row, index) in awaiting_consumer_approval_transactions" :key="index">
                    <tr v-if="index < 4">
                      <td class="text-center">
                        {{row.transaction_number}}
                      </td>
                      <td class="text-center">{{row.transaction_date}}</td>
                      <td class="text-center">
                        <small class="text-success mr-1" v-if="row.updated_amount && row.updated_amount > row.orginal_amount && row.attempt_count == 0">
                        ${{row.updated_amount.toFixed(2)}}
                        <i class="fas fa-arrow-up"></i>
                        </small>
                        <small class="text-success mr-1" v-if="row.updated_amount && row.updated_amount > row.orginal_amount && row.attempt_count > 0">
                          ${{row.orginal_amount.toFixed(2) + "... $" + row.updated_amount.toFixed(2)}}
                        <i class="fas fa-arrow-up"></i>
                        </small>
                        <small class="text-danger mr-1" v-else-if="row.updated_amount && row.updated_amount < row.orginal_amount && row.attempt_count == 0">
                        ${{row.updated_amount.toFixed(2)}}
                        <i class="fas fa-arrow-down"></i>
                        </small>
                        <small class="text-danger mr-1" v-else-if="row.updated_amount && row.updated_amount < row.orginal_amount && row.attempt_count > 0">
                          ${{row.orginal_amount.toFixed(2) + "... $" + row.updated_amount.toFixed(2)}}
                        <i class="fas fa-arrow-down"></i>
                        </small>
                        <small v-else>
                        ${{row.orginal_amount.toFixed(2)}}
                        </small>
                        <a type="button" v-if="row.attempt_count > 0" class="pendingviewModifiedTransaction custom-edit-btn" title="View All Modified Transaction" variant="outline-success" @click="viewModificationHistory(row)"><i class="nav-icon fas fa-eye"></i></a>
                      </td>
                      <td class="text-center">
                        <small class="text-success mr-1" v-if="row.last_updated_tip_amount > row.orginal_tip_amount">
                        ${{row.last_updated_tip_amount.toFixed(2)}}
                        <i class="fas fa-arrow-up"></i>
                        </small>
                        <small class="text-danger mr-1" v-else-if="row.last_updated_tip_amount < row.orginal_tip_amount">
                        ${{row.last_updated_tip_amount.toFixed(2)}}
                        <i class="fas fa-arrow-down"></i>
                        </small>
                        <small v-else>
                        ${{row.last_updated_tip_amount.toFixed(2)}}
                        </small>
                      </td>
                      <td class="text-center">
                        <small>
                        ${{row.delivery_fee}}
                        </small>
                      </td>
                      <td class="text-center">
                        <small v-if="row.time_left_for_approval != ''">
                          <vue-countdown-timer
                            :start-time="row.current_store_datetime"
                            :end-time="row.approval_expiration_datetime"
                            :interval="1000"
                            :end-text="'Request Timeout'"
                            :day-txt=false
                            :hour-txt="':'"
                            :minutes-txt="':'"
                            secondsTxt="">
                          </vue-countdown-timer>
                        </small>
                        <small v-else>Request Timeout</small>
                      </td>
                      <td class="text-center">
                        <button type="button" class="btn btn-success ecommerce-leave-modify mb-2" v-if="row.consumer_approval_for_change_request == 0 && (current_date <= row.expiry_date_for_book_now || row.expiry_date_for_book_now == '')" style="color: white !important; margin-bottom: 2px;"  @click="ecommerceModifyLeaveComment($event)" :data-tr-number="row.transaction_number" :data-tr-amount="row.last_approve_amount" :data-tr-tip-amount="row.last_approve_tip_amount" :data-tr-delivery_fee="row.delivery_fee" :data-id="row.edit" :data-tran-status="row.status"><i class="fa fa-edit" aria-hidden="true"></i> Modify </button>
                        <!-- line gap -->
                        <br v-if="row.consumer_approval_for_change_request == 0">
                        <button type="button" class="btn btn-danger ecommerce-leave-comment" v-if="row.cancelable == 1 && row.is_v1 == 0 && (current_date <= row.expiry_date_for_book_now || row.expiry_date_for_book_now == '')" style="color: white !important; margin-bottom: 2px;" :data-id="row.edit"><i class="fa fa-times fa-lg"></i> Void </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="card" style="height: 400px;">
              <div class="card-header border-0">
                <div class="row mb-3">
                  <div class="col-4 d-flex align-items-center"> 
                    <h3 class="card-title mb-0" style="font-size: 16px;">Modification Accepted</h3>
                  </div>
                </div>
                <div>
                  <a v-if="approved_transactions.length > 4" href="javascript:void(0)" class="btn btn-sm btn-primary float-right" @click="viewmoreTransactions('Approved')">View more</a>
                </div>
              </div>
              <div  class="card-body table-responsive p-0 text-center no-record-found"  v-if="!approved_transactions.length">
                No Record Found
              </div>
              <div class="card-body table-responsive p-0" v-else>
                <table class="table table-striped table-valign-middle">
                  <thead>
                    <tr>
                      <th class="text-center">Transaction Number</th>
                      <th class="text-center">Transaction Date</th>
                      <th class="text-center">Settlement Date</th>
                      <th class="text-center" width="15%">Amount</th>
                      <th class="text-center">Tip Amount</th>
                      <th class="text-center">Delivery Fee</th>
                      <th class="text-center">Expiration Date</th>
                      <th class="text-center">Action</th>
                      <th v-if="is_ecommerce_admin_driven==1" class="text-center">Book Transaction</th>
                    </tr>
                  </thead>
                  <tbody v-for="(row, index) in approved_transactions" :key="index">
                    <tr v-if="index < 4">
                      <td class="text-center">
                        {{row.transaction_number}}
                      </td>
                      <td class="text-center">{{row.transaction_date}}</td>
                      <td class="text-center">{{row.scheduled_posting_date}}</td>
                      <td class="text-center">
                        <small class="text-success mr-1" v-if="row.updated_amount && row.updated_amount > row.orginal_amount && row.attempt_count == 0">
                        ${{row.updated_amount.toFixed(2)}}
                        <i class="fas fa-arrow-up"></i>
                        </small>
                        <small class="text-success mr-1" v-if="row.updated_amount && row.updated_amount > row.orginal_amount && row.attempt_count > 0">
                          ${{row.orginal_amount.toFixed(2) + "... $" + row.updated_amount.toFixed(2)}}
                        <i class="fas fa-arrow-up"></i>
                        </small>
                        <small class="text-danger mr-1" v-else-if="row.updated_amount && row.updated_amount < row.orginal_amount && row.attempt_count == 0">
                        ${{row.updated_amount.toFixed(2)}}
                        <i class="fas fa-arrow-down"></i>
                        </small>
                        <small class="text-danger mr-1" v-else-if="row.updated_amount && row.updated_amount < row.orginal_amount && row.attempt_count > 0">
                          ${{row.orginal_amount.toFixed(2) + "... $" + row.updated_amount.toFixed(2)}}
                        <i class="fas fa-arrow-down"></i>
                        </small>
                        <small v-else>
                        ${{row.orginal_amount.toFixed(2)}}
                        </small>
                        <a type="button" v-if="row.attempt_count > 0" class="pendingviewModifiedTransaction custom-edit-btn" title="View All Modified Transaction" variant="outline-success" @click="viewModificationHistory(row)"><i class="nav-icon fas fa-eye"></i></a>
                      </td>
                      <td class="text-center">
                        <small class="text-success mr-1" v-if="row.last_updated_tip_amount > row.orginal_tip_amount">
                        ${{row.last_updated_tip_amount.toFixed(2)}}
                        <i class="fas fa-arrow-up"></i>
                        </small>
                        <small class="text-danger mr-1" v-else-if="row.last_updated_tip_amount < row.orginal_tip_amount">
                        ${{row.last_updated_tip_amount.toFixed(2)}}
                        <i class="fas fa-arrow-down"></i>
                        </small>
                        <small v-else>
                        ${{row.last_updated_tip_amount.toFixed(2)}}
                        </small>
                      </td>
                      <td class="text-center">
                        <small>
                        ${{row.delivery_fee}}
                        </small>
                      </td>
                      <td class="text-center">{{row.expiry_date_for_book_now}}</td>
                      <td class="text-center">
                        <button type="button" class="btn btn-success ecommerce-leave-modify mb-2" v-if="row.consumer_approval_for_change_request == 0 && (current_date <= row.expiry_date_for_book_now || row.expiry_date_for_book_now == '')" style="color: white !important; margin-bottom: 2px;"  @click="ecommerceModifyLeaveComment($event)" :data-tr-number="row.transaction_number" :data-tr-amount="row.last_approve_amount" :data-tr-tip-amount="row.last_approve_tip_amount" :data-tr-delivery_fee="row.delivery_fee" :data-id="row.edit" :data-tran-status="row.status"><i class="fa fa-edit" aria-hidden="true"></i> Modify </button>
                        <!-- line gap -->
                        <br v-if="row.consumer_approval_for_change_request == 0">
                        <button type="button" class="btn btn-danger ecommerce-leave-comment" v-if="row.cancelable == 1 && row.is_v1 == 0 && (current_date <= row.expiry_date_for_book_now || row.expiry_date_for_book_now == '')" style="color: white !important; margin-bottom: 2px;" :data-id="row.edit"><i class="fa fa-times fa-lg"></i> Void </button>
                      </td>
                      <td v-if="is_ecommerce_admin_driven==1" class="text-center">
                        <button type="button" @click="postToBankModel(row.edit)" class="btn btn-primary accept-payment" v-if="row.post_to_bank == 1 && current_date <= row.expiry_date_for_book_now" style="color: white !important; margin-bottom: 2px;" :data-id="row.edit"><i class="fa fa-edit" aria-hidden="true"></i> Accept Payment </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="card" style="height: 400px;">
              <div class="card-header border-0">
                <h3 class="card-title" style="font-size: 16px;">Modification Rejected</h3>
                <a v-if="declined_transactions.length > 4" href="javascript:void(0)" class="btn btn-sm btn-primary float-right" @click="viewmoreTransactions('Consumer Declined')">View more</a>
              </div>
              <div  class="card-body table-responsive p-0 text-center no-record-found"  v-if="!declined_transactions.length">
                No Record Found
              </div>
              <div class="card-body table-responsive p-0" v-else>
                <table class="table table-striped table-valign-middle td-pad-15">
                  <thead>
                    <tr>
                      <th class="text-center">Transaction Number</th>
                      <th class="text-center">Transaction Date</th>
                      <th class="text-center">Amount</th>
                      <th class="text-center">Tip Amount</th>
                      <th class="text-center">Delivery Fee</th>
                      <th class="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody  v-for="(row, index) in declined_transactions" :key="index">
                    <tr v-if="index < 4">
                      <td class="text-center">
                        {{row.transaction_number}}
                      </td>
                      <td class="text-center">{{row.transaction_date}}</td>
                      <td class="text-center">
                        <small class="text-success mr-1" v-if="row.updated_amount && row.updated_amount > row.orginal_amount && row.attempt_count == 0">
                        ${{row.updated_amount.toFixed(2)}}
                        <i class="fas fa-arrow-up"></i>
                        </small>
                        <small class="text-success mr-1" v-if="row.updated_amount && row.updated_amount > row.orginal_amount && row.attempt_count > 0">
                        ${{row.orginal_amount.toFixed(2) + "... $" + row.updated_amount.toFixed(2)}}
                        <i class="fas fa-arrow-up"></i>
                        </small>
                        <small class="text-danger mr-1" v-else-if="row.updated_amount && row.updated_amount < row.orginal_amount && row.attempt_count == 0">
                        ${{row.updated_amount.toFixed(2)}}
                        <i class="fas fa-arrow-down"></i>
                        </small>
                        <small class="text-danger mr-1" v-else-if="row.updated_amount && row.updated_amount < row.orginal_amount && row.attempt_count > 0">
                          ${{row.orginal_amount + "... $" + row.updated_amount.toFixed(2)}}
                        <i class="fas fa-arrow-down"></i>
                        </small>
                        <small v-else>
                        ${{row.orginal_amount.toFixed(2)}}
                        </small>
                        <a type="button" v-if="row.attempt_count > 0" class="pendingviewModifiedTransaction custom-edit-btn" title="View All Modified Transaction" variant="outline-success" @click="viewModificationHistory(row)"><i class="nav-icon fas fa-eye"></i></a>
                      </td>
                      <td class="text-center">
                        <small class="text-success mr-1" v-if="row.last_updated_tip_amount > row.orginal_tip_amount">
                        ${{row.last_updated_tip_amount.toFixed(2)}}
                        <i class="fas fa-arrow-up"></i>
                        </small>
                        <small class="text-danger mr-1" v-else-if="row.last_updated_tip_amount < row.orginal_tip_amount">
                        ${{row.last_updated_tip_amount.toFixed(2)}}
                        <i class="fas fa-arrow-down"></i>
                        </small>
                        <small v-else>
                        ${{row.last_updated_tip_amount.toFixed(2)}}
                        </small>
                      </td>
                      <td class="text-center">
                        <small>
                        ${{row.delivery_fee}}
                        </small>
                      </td>
                      <td class="text-center">
                        <button type="button" class="btn btn-success ecommerce-leave-modify mb-2" v-if="row.consumer_approval_for_change_request == 0 && (current_date <= row.expiry_date_for_book_now || row.expiry_date_for_book_now == '')" style="color: white !important; margin-bottom: 2px;"  @click="ecommerceModifyLeaveComment($event)" :data-tr-number="row.transaction_number" :data-tr-amount="row.last_approve_amount" :data-tr-tip-amount="row.last_approve_tip_amount" :data-tr-delivery_fee="row.delivery_fee" :data-id="row.edit" :data-tran-status="row.status"><i class="fa fa-edit" aria-hidden="true"></i> Modify </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="card" style="height: 400px;">
              <div class="card-header border-0">
                <h3 class="card-title" style="font-size: 16px;">Yesterday's Expired Transactions</h3>
                <a v-if="expired_transactions.length > 4" href="javascript:void(0)" class="btn btn-sm btn-primary float-right" @click="viewmoreTransactions('Expired')">View more</a>
              </div>
              <div  class="card-body table-responsive p-0 text-center no-record-found"  v-if="!expired_transactions.length">
                No Record Found
              </div>
              <div class="card-body table-responsive p-0" v-else>
                <table class="table table-striped table-valign-middle td-pad-15">
                  <thead>
                    <tr>
                      <th class="text-center">Transaction Number</th>
                      <th class="text-center">Transaction Date</th>
                      <th class="text-center">Amount</th>
                      <th class="text-center">Tip Amount</th>
                      <th class="text-center">Delivery Fee</th>
                      <th class="text-center">Status</th>
                    </tr>
                  </thead>
                  <tbody  v-for="(row, index) in expired_transactions" :key="index">
                    <tr v-if="index < 4">
                      <td class="text-center">
                        {{row.transaction_number}}
                      </td>
                      <td class="text-center">{{row.transaction_date}}</td>
                      <td class="text-center">
                        <small class="text-success mr-1" v-if="row.updated_amount && row.updated_amount > row.orginal_amount && row.attempt_count == 0">
                        ${{row.updated_amount.toFixed(2)}}
                        <i class="fas fa-arrow-up"></i>
                        </small>
                        <small class="text-success mr-1" v-if="row.updated_amount && row.updated_amount > row.orginal_amount && row.attempt_count > 0">
                        ${{row.orginal_amount.toFixed(2) + "... $" + row.updated_amount.toFixed(2)}}
                        <i class="fas fa-arrow-up"></i>
                        </small>
                        <small class="text-danger mr-1" v-else-if="row.updated_amount && row.updated_amount < row.orginal_amount && row.attempt_count == 0">
                        ${{row.updated_amount.toFixed(2)}}
                        <i class="fas fa-arrow-down"></i>
                        </small>
                        <small class="text-danger mr-1" v-else-if="row.updated_amount && row.updated_amount < row.orginal_amount && row.attempt_count > 0">
                          ${{row.orginal_amount + "... $" + row.updated_amount.toFixed(2)}}
                        <i class="fas fa-arrow-down"></i>
                        </small>
                        <small v-else>
                        ${{row.orginal_amount.toFixed(2)}}
                        </small>
                        <a type="button" v-if="row.attempt_count > 0" class="pendingviewModifiedTransaction custom-edit-btn" title="View All Modified Transaction" variant="outline-success" @click="viewModificationHistory(row)"><i class="nav-icon fas fa-eye"></i></a>
                      </td>
                      <td class="text-center">
                        <small class="text-success mr-1" v-if="row.last_updated_tip_amount > row.orginal_tip_amount">
                        ${{row.last_updated_tip_amount.toFixed(2)}}
                        <i class="fas fa-arrow-up"></i>
                        </small>
                        <small class="text-danger mr-1" v-else-if="row.last_updated_tip_amount < row.orginal_tip_amount">
                        ${{row.last_updated_tip_amount.toFixed(2)}}
                        <i class="fas fa-arrow-down"></i>
                        </small>
                        <small v-else>
                        ${{row.last_updated_tip_amount.toFixed(2)}}
                        </small>
                      </td>
                      <td class="text-center">
                        <small>
                        ${{row.delivery_fee}}
                        </small>
                      </td>
                      <td class="text-center">
                        {{ row.status }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-------------------------------------- Store Details Modal ---------------------------------------->
    <b-modal
      id="store-details-modal"
      ref="modal"
      :header-text-variant="headerTextVariant"
      title="Process To Bank Transaction Details"
      ok-variant="success"
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
      :hide-footer="true"
      >
      <div class="row">
        <div class="col-md-12 text-center" v-if="!storeWiseTransactionExpireCount.length">
          No Record Found
        </div>
        <div class="col-md-12" v-else>
          <table class="table table-striped table-valign-middle td-pad-15">
            <thead>
              <tr>
                <th>Store Name</th>
                <th class="text-center">Pending Process To Bank Transactions</th>
              </tr>
            </thead>
            <tbody  v-for="(row, index) in storeWiseTransactionExpireCount" :key="index">
              <tr>
                <td>
                  <b-button
                    @click="viewActivity(row.merchant_store_id)"
                    title="View Store Level Activity"
                    class="btn btn btn-success"
                    >{{row.retailer}}</b-button
                    >
                </td>
                <td class="text-center">{{row.total_expire}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </b-modal>
    <!-- Transaction Post to bank modal start -->
    <b-modal
      id="post-to-bank-model"
      ref="post-to-bank-model"
      hide-footer 
      hide-header
      >
      <div class="color">
        <div class="col-12 text-center">
          <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-send" width="120" height="120" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round" style="color: rgb(20, 146, 64);">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <line x1="10" y1="14" x2="21" y2="3"></line>
            <path d="M21 3l-6.5 18a0.55 .55 0 0 1 -1 0l-3.5 -7l-7 -3.5a0.55 .55 0 0 1 0 -1l18 -6.5"></path>
          </svg>
        </div>
        <div class="purchaserpower-modal-text">
          <div class="d-block text-center">
            <label class="text-justify text-secondary h4">
            Are you sure ?
            </label>
            <br />
            <label class="text-justify text-secondary text-dark">
            {{sendToBankModalMsg}}
            </label>
          </div>
          <div class="row">
            <div class="col-12 text-center">
              <button
                @click="hideModal('post-to-bank-model')"
                class="btn btn-secondary btn-md center-block mr-2"
                >
              <label class="forgetpassword-ok-label mb-0">Cancel</label>
              </button>
              <button
                @click="sendToBank()"
                class="btn btn-success btn-md center-block ml-2"
                >
              <label class="forgetpassword-ok-label mb-0">Confirm</label>
              </button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <!-- Transaction Post to bank modal end -->
    <!-------------------------------------- Transaction Pending Modal ---------------------------------------->
    <b-modal
      id="view-more-transactions-modal"
      ref="modal"
      :header-text-variant="headerTextVariant"
      :title="viewMoreHeaderText"
      ok-variant="success"
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
      :hide-footer="true"
      >
      <div class="row">
        <div class="loading" v-if="modalloading">
          <hour-glass></hour-glass>
        </div>
        
        <div class="col-md-12" v-if="advance_search">
          <div class="card-body">
            <div class="row">
              <div class="col-md-4">
                <label for="store">Select Store <span class="red">*</span></label>
                <div class="form-group">
                  <multiselect
                    id="store"
                    name="store"
                    v-model="advanceSearchelectedStore"
                    placeholder="Select Store"
                    label="retailer"
                    track-by="id"
                    :options="storeList"
                    :multiple="false"
                    :searchable="true"
                    :show-labels="false"
                    ></multiselect>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                   <label for="start-date">Select Start Date <span class="red">*</span></label>
                  <input
                    class="start-date form-control"
                    placeholder="Start Date"
                    id="advance-search-start-date"
                    onkeydown="return false"
                    autocomplete="off"
                    />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="end-date">Select End Date <span class="red">*</span></label>
                  <input
                    class="end-date form-control"
                    placeholder="End Date"
                    id="advance-search-end-date"
                    onkeydown="return false"
                    autocomplete="off"
                    />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <input type="radio" name="selecteSearchDateType" id="settlement_date" value="settlement_date" v-model="filter_date_type">&nbsp;<label for="settlement_date">Settlement Date </label>&nbsp;&nbsp;
                  <input type="radio" name="selecteSearchDateType" id="transaction_date" value="transaction_date" v-model="filter_date_type">&nbsp;<label for="transaction_date">Transaction Date</label>
                </div>
              </div>
            </div>
            <div class="row">
              
              <div class="col-md-4">
                <div class="form-group">
                   <label for="start-date">Transaction Number</label>
                   <input
                        class="form-control"
                        id="transaction_number"
                        v-model="transaction_number"
                      />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="start-date">Amount</label>
                   <input
                        class="form-control"
                        id="transaction_amount"
                        v-model="transaction_amount"
                      />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="start-date">Updated Amount</label>
                   <input
                        class="form-control"
                        id="transaction_updated_amount"
                        v-model="transaction_updated_amount"
                      />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <button type="button" class="btn btn-success mr-3" @click="generateAdvanceSearchReport()"> Search </button>
                  <button type="button" class="btn btn-info mr-3" @click="generateAdvanceSearchReport()"> <i class="fas fa-redo"></i> </button>
                    <button type="button" class="btn btn-dark" @click="generateAdvanceSearchReset()"> Reset </button>
                </div>
              </div>
            </div>
            
          </div>
        </div>
        <div class="col-md-12">
        </div>
        <div class="col-md-12">
          <table class="table table-striped table-valign-middle td-pad-5" id="transactionTable">
            <thead>
              <tr>
                <th width="15%">Transaction Number</th>
                <th width="15%">Transaction Date</th>
                <th class="15%">Settlement Date</th>
                <th width="10%">Amount</th>
                <th width="5%">Tip Amount</th>
                <th width="5%">Delivery Fee</th>
                <th width="15%">Expiration Date</th>
                <th width="10%">Status</th>
                <th width="10%" class="text-center">Action</th>
                <th class="text-center">Void Transaction</th>
                <th width="10%">Time Left</th>
              </tr>
            </thead>
          </table>
        </div>
      </div>
    </b-modal>
    <!-- Transaction cancellation comment modal start -->
    <b-modal
      id="comment-modal"
      ref="comment-modal"
      ok-title="Save & Cancel"
      cancel-title="Close"
      ok-variant="success"
      @ok="cancelTransaction"
      cancel-variant="outline-secondary"
      hide-header
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
    >
      <div class="row">
        <div class="col-12">
          <label for="comment">
            Tell us why you want to cancel this transaction
            <span class="red">*</span>
          </label>
          <textarea
            name="comment"
            type="text"
            v-model="comment"
            class="form-control"
          />
        </div>
        <input type="text" v-model="transaction_id" hidden />
      </div>
      <div class="row" v-if="showMsg">
        <div class="col-12">
          <label for="comment" class="red"
            >Please fill in the required fields.</label
          >
        </div>
      </div>
      <div class="row" style="margin-bottom: 40px"></div>
    </b-modal>
    <!-- Transaction cancellation comment modal end -->
    <!-- Transaction modification modal step 1 start -->
    <b-modal
      id="modification-modal"
      ref="modification-modal"
      ok-title="Modify"
      cancel-title="Close"
      ok-variant="success"
      @ok="modifyTransactionStep1"
      cancel-variant="outline-secondary"
      :header-text-variant="headerTextVariant"
      :title="modalTitle"
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
    >
      <div class="row">
          <div class="col-6">
            <div class="form-group">
              <label>Select Modification Type <span class="red">*</span></label>&nbsp;<br />
              <input type="radio" name="selectedReasonType" id="radio1" value="increase" @change="selecteReasonType($event)" v-model="selectedReasonType">&nbsp;Increase &nbsp;&nbsp;
              <input type="radio" name="selectedReasonType" id="radio2" value="decrease" @change="selecteReasonType($event)" v-model="selectedReasonType">&nbsp;Decrease<br />
              <span class="red" v-if="reasonTypeSelectError" style="">{{reasonTypeSelectErrorMsg}}</span>
            </div>
          </div>
          <div class="col-6">
            <label for="reason">
              Select Modification Reason
              <span class="red">*</span>
            </label>
            <multiselect
              id="reason"
              name="reason"
              v-model="selectedReason"
              placeholder="Select Reason"
              label="reason"
              track-by="id"
              :options="modificationReasonList"
              @select="selecteReason"
              :multiple="false"
              :searchable="true"
              :show-labels="false"
            ></multiselect>
            <span class="red" v-if="reasonSelectError" style="">{{reasonSelectErrorMsg}}</span>
          </div>
      </div>     
      <br>
      <div class="row">
          
          <div class="loading" v-if="modalloading">
            <hour-glass></hour-glass>
          </div>
          <div class="col-6">
            <label for="modification">
              Current Payment Amount($)
            </label>
            <input
              name="tr_amount"
              type="text"
              readonly
              v-model="tr_amount"
              class="form-control numberonly amount_input"
            />
            <i class="fa fa-dollar-sign fa-lg doller_icon"></i>
          </div>
          <div class="col-6">
            <label for="modification">
              Updated Transaction Amount($)
              <span class="red">*</span>
            </label>
            <input
              @input="onKeyupUpdatedAmount"
              name="updated_amount"
              type="text"
              v-model="updated_amount"
              class="form-control numberonly amount_input"
            />
            <i class="fa fa-dollar-sign fa-lg doller_icon"></i>
            <span class="red" v-if="UpAmtMsg" style="">{{UpAmtErrorMsg}}</span>
          </div>
      </div>
      <br> 
      <div class="row">
          <div class="col-6" v-if="tip_amount > 0">
            <label for="modification">
              Tip Amount($)
            </label>
            <input
              name="tip_amount"
              type="text"
              readonly
              v-model="tip_amount"
              class="form-control numberonly amount_input"
            />
            <i class="fa fa-dollar-sign fa-lg doller_icon"></i>
          </div>
          <div class="col-6" v-if="delivery_fee > 0">
            <label for="modification">
              Delivery Fee($)
            </label>
            <input
              name="delivery_fee"
              type="text"
              readonly
              v-model="delivery_fee"
              class="form-control numberonly amount_input"
            />
            <i class="fa fa-dollar-sign fa-lg doller_icon"></i>
          </div>
          <div class="col-6" v-if="reasonSelectOther">
            <label for="reason_text">
              Provide the reason you are modifying this payment
              <span class="red"  v-if="reasonSelectOther">*</span>
            </label>
            <textarea
              name="reason_text"
              type="text"
              v-model="reasonComment"
              class="form-control"
              style="height: 70px;resize: none;" 
            />
            <span class="red" v-if="reasonCommentError" style="">{{reasonCommentErrorMsg}}</span>
          </div>

         <div :class="delivery_fee>0 && tip_amount > 0 ? 'col-6 mt-3' : 'col-6'">
            <label for="modification">
              Status
            </label>
            <input
              type="text"
              readonly
              v-model="modification_status"
              class="form-control"
            />
          </div>
        <input type="text" v-model="transaction_id" hidden />
      </div>      
      <div class="row" style="margin-bottom: 40px"></div>
    </b-modal>
    <!-- Transaction modification modal step 1 end -->
    <!-- Transaction modification modal step 2 start -->
    <b-modal
      id="modification-modal-step-2"
      ref="modification-modal-step-2"
      hide-footer 
      hide-header
    >
      <div class="color">
        <div class="col-12 text-center">
          <svg width="100" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 330 330" style="enable-background:new 0 0 330 330;" xml:space="preserve">
            <g id="XMLID_23_">
            <path fill="#149240" id="XMLID_24_" d="M75,180v60c0,8.284,6.716,15,15,15h60c3.978,0,7.793-1.581,10.606-4.394l164.999-165
            c5.858-5.858,5.858-15.355,0-21.213l-60-60C262.794,1.581,258.978,0,255,0s-7.794,1.581-10.606,4.394l-165,165
            C76.58,172.206,75,176.022,75,180z M105,186.213L255,36.213L293.787,75l-150,150H105V186.213z"/>
            <path fill="#149240" id="XMLID_27_" d="M315,150.001c-8.284,0-15,6.716-15,15V300H30V30H165c8.284,0,15-6.716,15-15s-6.716-15-15-15H15
            C6.716,0,0,6.716,0,15v300c0,8.284,6.716,15,15,15h300c8.284,0,15-6.716,15-15V165.001C330,156.716,323.284,150.001,315,150.001z"/></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g>
          </svg>
        </div>
        <div class="purchaserpower-modal-text">
          <div class="d-block text-center">
            <label class="text-justify text-secondary h4">
                Are you sure ?
            </label>
            <br />
            <label class="text-justify text-secondary text-dark">
                {{modifyTransactionMessage}}
            </label>
          </div>
          <div class="row">
            <div class="col-12 text-center">
                <button
                @click="hideModal('modification-modal-step-2')"
                class="btn btn-secondary btn-md center-block mr-2"
                >
                <label class="forgetpassword-ok-label mb-0">Cancel</label>
                </button>
                <button
                @click="modifyTransaction()"
                class="btn btn-success btn-md center-block ml-2"
                >
                <label class="forgetpassword-ok-label mb-0">{{ModifyButton}}</label>
                </button>
            </div>
          </div>
          </div>
      </div>      
    </b-modal>
    <!-- Transaction modification modal step 2 end -->
    <!-- Transaction modification history modal start -->
    <b-modal
      id="modified-tr-history-modal"
      ref="modified-tr-history-modal"
      ok-only
      cancel-variant="outline-secondary"
      :header-text-variant="headerTextVariant"
      :title="historyModalTitle"
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
    >
      <div class="row">
          <div class="col-md-12">
            <b-table-simple
            class="cp-table"
            responsive
            show-empty
            bordered
            >
                <b-thead head-variant="light">
                    <tr>
                        <th width="15%">Amount ($)</th>
                        <th width="20%">Tip Amount ($)</th>
                        <th width="20%">Delivery Fee ($)</th>
                        <th width="20%">Modify Time</th>
                        <th width="20%">Reason</th>
                        <th width="20%">Additional Reason</th>
                        <th width="15%">Status</th>
                    </tr>
                </b-thead>
                <b-tbody v-for="(row, index) in transactionHistory" :key="index">
                   <b-tr>
                          <b-td class="text-left text-gray">${{
                            row.amount
                          }}</b-td>
                          <b-td class="text-left text-gray">${{
                            row.tip_amount
                          }}</b-td>
                          <b-td class="text-left text-gray">${{
                            row.delivery_fee
                          }}</b-td>
                          <b-td class="text-left text-gray">{{
                            row.local_transaction_time
                          }}</b-td>
                          <b-td class="text-left text-gray">{{
                            row.reason
                          }}</b-td>
                          <b-td class="text-left text-gray">{{
                            row.additional_reason
                          }}</b-td>
                          <b-td class="text-left text-gray">{{
                            row.status
                          }}</b-td>
                        </b-tr>
                </b-tbody>
            </b-table-simple>
          </div>
        </div>
    </b-modal>
    <!-- Transaction modification history modal end -->
    <!-- Button Info Modal -->
    <b-modal
      id="button-info-modal"
      ref="button-info-modal"
      cancel-variant="outline-secondary"
      hide-header
      hide-footer
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
    >
      <div class="row">
        <div class="col-12">
          <h5>Global Buttons Information</h5>
        </div>
        <div class="col-12">
          <ul>
            <li class="mb-2"><strong>Modify:</strong> You can use it to change the transaction amount. This button can't be used with multiple selection. You need to select one Single transaction to "Modify" it.</li>
            <li class="mb-2"><strong>Accept Payment:</strong> Accept Payment is going to post transactions to Bank.</li>
            <li class="mb-2"><strong>Void:</strong> Use for cancel transaction and it will be enabled if transaction is cancelable.</li>
            <li class="mb-2"><strong>Notes:</strong> Modify and void button will be enabled if only one transaction selected and accept payment button will be enabled for multiple or single transaction.</li>
          </ul>
        </div>
        <div class="col-12 row justify-center m-0">
          <button class="btn btn-success w-100" @click="hideModal('button-info-modal')">Okay</button>
        </div>
      </div>
      <div class="row" style="margin-bottom: 40px"></div>
    </b-modal>
    <!-- Button Info Modal -->
  </div>
</template>
<script>
  import api from "@/api/reports.js";
  import transaction_api from "@/api/transaction.js";
  import user_api from "@/api/user.js";
  import Constants from "@/common/constant.js";
  import moment from "moment";
  import { HourGlass } from "vue-loading-spinner";
  import { saveAs } from "file-saver";

  export default {
    data() {
      return {
        headerTextVariant:"light",
        storeWiseTransactionExpireCount: [],
        pending_and_unpaid_transactions: [],
        awaiting_consumer_approval_transactions: [],
        approved_transactions: [],
        declined_transactions:[],
        expired_transactions: [],
        expired_count_today:0,
        expired_count_tomorrow:0,
        expired_count_dayaftertomorrow:0,
        storeList: [],
        selectedStore: "",
        expire_date: "",
        from_date: "",
        to_date: "",
        advance_search: false,
        advanceSearchelectedStore: "",
        advance_search_from_date: "",
        advance_search_to_date: "",
        transaction_number: "",
        transaction_amount: "",
        transaction_updated_amount: "",
        loading: false,
        modalloading: false,
        postEvent: false,
        transaction_id: null,
        sendToBankModalMsg: '',
        viewMoreHeaderText: '',
        viewMoreType: '',
        hidColumns: [],
        Constants: Constants,
        searchDate: moment($().val()).format("YYYY-MM-DD"),
        comment: "",
        showMsg: false,
        updated_amount: '',
        modificationReasonList: [],
        selectedReason: '',
        reasonSelectError: false,
        reasonSelectErrorMsg: '',
        reasonComment: '',
        reasonCommentError: false,
        reasonCommentErrorMsg: '',
        reasonSelectOther: false,
        modalTitle: "Modify payment amount",
        selectedReasonType : '',
        modificationReasonTypeList : 
        [
          { 'id': 'increase', 'name': 'Increase' },
          { 'id': 'decrease', 'name': 'Decrease' }
        ],
        reasonTypeSelectError : false,
        reasonTypeSelectErrorMsg : '',
        modifyTransactionMessage: '',
        ModifyButton: '',
        UpAmtMsg: '',
        UpAmtErrorMsg: '',
        tr_amount: null,
        tip_amount: null,
        delivery_fee:null,
        transactionHistory: [],
        historyModalTitle: "Transaction history",
        view_more: false,
        is_ecommerce_admin_driven: '',
        is_preloaded_dashboard: false,
        current_date: moment().format("MM-DD-YYYY"),
        modification_status: '',
        filter_date_type: 'settlement_date',
      };
    },
    components: {
      HourGlass,
    },
    created() {
      this.ecommerceDashboardLeaveComment();
    },
    mounted() {
      var self = this;
       
      self.getTransactionToExpireDetails();   
      self.getStores();   
      self.setDatepicker();   
    },
    methods: {
      // set date picker
      setDatepicker() {
        $("#start-date").datepicker({
          format: "mm/dd/yyyy",
          autoclose: true,
          todayHighlight: true,
          startDate: new Date('2021-07-01'),
        });
        $("#end-date").datepicker({
          format: "mm/dd/yyyy",
          autoclose: true,
          todayHighlight: true,
        });
        $("#start-date").datepicker("setDate", "-7d");
        $("#end-date").datepicker("setDate", new Date());
      }, 
      // set advance date picker
      setAdvanceDatepicker() {
        $(function() {
          $("#advance-search-start-date").datepicker({
            format: "mm/dd/yyyy",
            autoclose: true,
            todayHighlight: true,
            startDate: new Date('2021-07-01'),
          });
          $("#advance-search-end-date").datepicker({
            format: "mm/dd/yyyy",
            autoclose: true,
            todayHighlight: true,
          });
          $("#advance-search-start-date").datepicker("setDate", "-7d");
          $("#advance-search-end-date").datepicker("setDate", new Date());
        });
      }, 
      // show model method
      showModal(type) {
        this.$bvModal.show(type)
      }, 
      // show model method
      showAdvanceSearchModal() {
        var self = this;
        self.advance_search = true;
        self.viewMoreHeaderText = 'Advanced Search';
        self.filter_date_type = 'settlement_date';
        self.hidColumns = [];
        self.showModal('view-more-transactions-modal');
        self.setAdvanceDatepicker();   
      }, 
      // hide model method
      hideModal(type) {
        this.$bvModal.hide(type)
      }, 
      // hide model method
      generateReportReset() {
        var self = this;
        self.selectedStore = '';
        self.from_date = '';
        self.to_date = '';
        $("#start-date").datepicker("setDate", '');
        $("#end-date").datepicker("setDate", '');
      }, 
      generateAdvanceSearchReset() {
        var self = this;
        self.advanceSearchelectedStore = '';
        self.advance_search_from_date = '';
        self.advance_search_to_date = '';
        self.transaction_number = '';
        self.transaction_amount = '';
        self.transaction_updated_amount = '';
        $("#advance-search-start-date").datepicker("setDate", '');
        $("#advance-search-end-date").datepicker("setDate", '');
      }, 
      // view dashboard data by click on store
      viewActivity(store_id) {
        var self = this;
        self.selectedStore = self.storeList.find(store => store.id === store_id);
        var expire_date = new Date(self.expire_date);
        expire_date.setDate(expire_date.getDate() - 7);
        $("#start-date").datepicker("setDate", new Date(expire_date));
        $("#end-date").datepicker("setDate", new Date());
        self.hideModal('store-details-modal');
        self.generateReport();
      }, 
      // dynamic date generate
      showDate(type) {
        var date = moment().add("days", type);
        return date.format("MM-DD-YYYY");
      }, 
      getStores() {
        var self = this;
        self.loading = true;
        let request = {
          params: {
            is_ecommerce: 1
            }
        };
        user_api
          .getStores(request)
          .then((response) => {
            if ((response.code == 200)) {
              self.storeList = response.data;
              if(self.storeList.length == 1){
                self.selectedStore = response.data[0];
              }
            } else {
              error(response.message);
            }
            self.loading = false;
          })
          .catch((err) => {
            self.loading = false;
            error(err.response.data.message);
          });
      },
      getTransactionToExpireDetails() {
        var self = this;
        var request = {
          today: self.showDate(0),
        };
        self.loading = true;
        api
          .getMerchantTransactionToExpireDetails(request)
          .then(function (response) {
            if (response.code == 200) {
              //Populate the Boxes
              self.expired_count_today = response.data.today;
              self.expired_count_tomorrow = response.data.tomorrow;
              self.expired_count_dayaftertomorrow = response.data.dayaftertomorrow;
              self.loading = false;
            } else {
              error(response.message);
              self.loading = false;
            }
          })
          .catch(function (error) {
            // error(error);
            self.loading = false;
          });
      },
      //date tye 0= today, 1= tomorrow, 2 = day after tomorrow
      viewStoreWiseTransactionExpireCount(date_type) {
        var self = this;
        self.expire_date = self.showDate(date_type);
        var request = {
          expire_date: self.expire_date,
        };
        self.loading = true;
        api
          .viewStoreWiseTransactionExpireCount(request)
          .then(function (response) {
            if (response.code == 200) {
              self.storeWiseTransactionExpireCount = response.data.transactions;
              self.showModal('store-details-modal');
              self.loading = false;
            } else {
              error(response.message);
              self.loading = false;
            }
          })
          .catch(function (error) {
            // error(error);
            self.loading = false;
          });
      },
      generateAdvanceSearchReport() {
        var self = this;
        var self = this;
        var start_date = $("#advance-search-start-date").val();
        var end_date = $("#advance-search-end-date").val();
        if(self.advanceSearchelectedStore == ''){
          error("Please select store.");
          return false;
        }
        if(start_date == ''){
          error("Please select Start Date.");
          return false;
        }
        if(end_date == ''){
          error("Please select End Date.");
          return false;
        }
        
        if (
          moment(start_date).format("YYYY-MM-DD") >
          moment().format("YYYY-MM-DD")
        ) {
          error("The start date cannot be in the future.");
          return false;
        }
        if (
          moment(end_date).format("YYYY-MM-DD") >
          moment().format("YYYY-MM-DD")
        ) {
          error("The end date cannot be from future.");
          return false;
        }
        if (
          moment(start_date).format("YYYY-MM-DD") >
          moment(end_date).format("YYYY-MM-DD")
        ) {
          error("The end date is not greater than the start date.");
          return false;
        }
        self.advance_search_from_date = moment(start_date).format("YYYY-MM-DD");
        self.advance_search_to_date = moment(end_date).format("YYYY-MM-DD");
        self.loadAdvanceSearchTransaction();
      },
      loadAdvanceSearchTransaction() {
        var self = this;
        var dataString = {
          from_date: self.advance_search_from_date,
          to_date: self.advance_search_to_date,
          store_id: self.advanceSearchelectedStore.id,
          transaction_number: self.transaction_number,
          amount: self.transaction_amount,
          updated_amount: self.transaction_updated_amount,
          is_advance_search: true,
          filter_date_type : self.filter_date_type,
        };
        self.loadDT(dataString);
      },
      generateReport() {
        var self = this;
        var self = this;
        var start_date = $("#start-date").val();
        var end_date = $("#end-date").val();
        if(self.selectedStore == ''){
          error("Please select store.");
          return false;
        }
        if(start_date == ''){
          error("Please select Start Date.");
          return false;
        }
        if(end_date == ''){
          error("Please select End Date.");
          return false;
        }
        
        if (
          moment(start_date).format("YYYY-MM-DD") >
          moment().format("YYYY-MM-DD")
        ) {
          error("The start date cannot be in the future.");
          return false;
        }
        if (
          moment(end_date).format("YYYY-MM-DD") >
          moment().format("YYYY-MM-DD")
        ) {
          error("The end date cannot be from future.");
          return false;
        }
        if (
          moment(start_date).format("YYYY-MM-DD") >
          moment(end_date).format("YYYY-MM-DD")
        ) {
          error("The end date is not greater than the start date.");
          return false;
        }
        
        self.from_date = moment(start_date).format("YYYY-MM-DD");
        self.to_date = moment(end_date).format("YYYY-MM-DD");
        self.loadTransactionModificationDT();
      },
      loadTransactionModificationDT() {
        var self = this;
        var request = {
          from_date: self.from_date,
          to_date: self.to_date,
          store_id: self.selectedStore.id,
        };
        self.loading = true;
        api
          .preLoadTransactionModification(request)
          .then(function (response) {
            if (response.code == 200) {
              self.is_preloaded_dashboard = true;
              self.pending_and_unpaid_transactions = response.data.pending_and_unpaid_transactions;
              self.awaiting_consumer_approval_transactions = response.data.awaiting_consumer_approval_transactions;
              self.approved_transactions = response.data.approved_transactions;
              self.declined_transactions = response.data.declined_transactions;
              self.expired_transactions = response.data.expired_transactions;
              self.is_ecommerce_admin_driven = response.data.is_ecommerce_admin_driven;
              self.loading = false;
              self.loadDT();
            } else {
              error(response.message);
              self.loading = false;
            }
          })
          .catch(function (error) {
            // error(error);
            self.loading = false;
          });
      },
      postToBankModel(id){
        var self = this
        self.sendToBankModalMsg = 'Do you want to post this transaction to the bank?';
        self.$bvModal.show("post-to-bank-model");
        self.transaction_id = id;
      },
      sendToBank(request){
        var self = this;
        self.hideModal('post-to-bank-model');
        if(self.postEvent){
          self.modalloading = true;
        }else{
          self.loading = true;
        }
        self.UpAmtMsg = false;
        var request = {
          transaction_ids: [self.transaction_id],
        };
        transaction_api
          .postToBank(request)
          .then((response) => {
            if ((response.code = 200)) {
              success(response.message);
              self.hideModal('view-more-transactions-modal');
              self.getTransactionToExpireDetails(); 
              if(self.selectedStore != '' && self.from_date != '' && self.to_date != ''){
                self.loadTransactionModificationDT();
              }
              if(self.postEvent){
                self.postEvent.closest('tr').find('.expire_date').html('');
                self.postEvent.hide();
              }
            } else {
              error(response.message);
            }
            self.loading = false;
            self.modalloading = false;
            self.postEvent = false;
          })
          .catch((err) => {
            self.loading = false;
            self.modalloading = false;
            self.postEvent = false;
            error(err);
          });
      },
      viewmoreTransactions(viewMoreType){
        var self = this;
        self.advance_search = false;
        $('#transactionTable').hide();
        self.viewMoreType = viewMoreType;
        self.hidColumns = [9];
        if(viewMoreType == 'Pending And Unpaid'){
          self.viewMoreHeaderText = 'Transaction Pending';
          self.hidColumns = [9];
        }else if(viewMoreType == 'Awaiting Consumer Approval'){
          self.viewMoreHeaderText = 'Consumer Approval Awaiting';
          self.hidColumns = [2,5,6];
        }else if(viewMoreType == 'Approved'){
          self.viewMoreHeaderText = 'Modification Accepted';
          self.hidColumns = [6,9];
        }else if(viewMoreType == 'Consumer Declined'){
          self.viewMoreHeaderText = 'Modification Rejected';
          self.hidColumns = [2,5,6,8,9];
        }
        else if(viewMoreType == 'Expired'){
          self.viewMoreHeaderText = "Yesterday's Expired Transactions";
          self.hidColumns = [2,5,7,8,9];
        }
        self.loadMoreData();
      },
      loadMoreData(){
        var self = this;
        // self.loading = true;
        self.showModal('view-more-transactions-modal');
         setTimeout(function () {
          var dataString = {
            from_date: self.from_date,
            to_date: self.to_date,
            store_id: self.selectedStore.id,
            type: self.viewMoreType,
          };
          self.loadDT(dataString);
        }, 100);
      },
      loadDT: function (dataString) {
        var self = this;
        $("#transactionTable").DataTable({
          pagingType: "simple_numbers",
          processing: true,
          serverSide: true,
          destroy: true,
          scrollY: 300,
          scrollX: true,
          ordering: false,
          columnDefs: [
              { 'visible': false, 'targets': self.hidColumns },
              { className: "expire_date", targets: [4] },
          ],
          language: {
            processing:
              '<i class="fa fa-spinner fa-spin fa-3x fa-fw"></i><span class="sr-only">Loading...</span> ',
            emptyTable: "No Transactions Available.",
            search: "_INPUT_",
            searchPlaceholder: "Search records",
            oPaginate: {
              sNext: '<i class="fas fa-angle-double-right"></i>',
              sPrevious: '<i class="fas fa-angle-double-left"></i>',
            },
          },
          ajax: {
            headers: {
              Authorization: localStorage.getItem("token"),
            },
            url: process.env.VUE_APP_API_URL + "/merchantadmin/ecommercedashboard/loadtransactionmodification",
            type: "POST",
            data: dataString,
            dataType: "json",
            dataSrc: function (result) {
              self.is_ecommerce_admin_driven = result.is_ecommerce_admin_driven;
              $('#transactionTable_processing').hide();
              return result.data;
            },
            error: function (request, status, error) {
              if (request.responseJSON.code == 401 && request.responseJSON.data != null) {
                localStorage.setItem("token", request.responseJSON.data);
                self.loadDT(dataString);
              }else{
                // error(Constants.datatable_error);
                $('#transactionTable_processing').hide();
              }
            },
          },
          columns: [
            { data: "transaction_number" },
            { data: "transaction_date" },          
            { data: "scheduled_posting_date" },          
            {
              render: function (data, type, row, meta) {
                if (row.updated_amount && row.updated_amount > row.orginal_amount) {
                  return ('<small class="text-success mr-1">$'+row.updated_amount.toFixed(2)+'<i class="fas fa-arrow-up"></i></small>');
                } else if(row.updated_amount && row.updated_amount < row.orginal_amount){
                   return ('<small class="text-danger mr-1">$'+row.updated_amount.toFixed(2)+'<i class="fas fa-arrow-down"></i></small>');
                }else{
                  return ('<small>$'+row.orginal_amount.toFixed(2)+'</small>');
                }
              },
            },    
            {
              render: function (data, type, row, meta) {
                if (row.last_updated_tip_amount > row.orginal_tip_amount) {
                  return ('<small class="text-success mr-1">$'+row.last_updated_tip_amount.toFixed(2)+'<i class="fas fa-arrow-up"></i></small>');
                } else if(row.last_updated_tip_amount < row.orginal_tip_amount){
                   return ('<small class="text-danger mr-1">$'+row.last_updated_tip_amount.toFixed(2)+'<i class="fas fa-arrow-down"></i></small>');
                }else{
                  return ('<small>$'+row.last_updated_tip_amount.toFixed(2)+'</small>');
                }
              },
            },
            {
              render: function (data, type, row, meta) {
                return ('<small class="mr-1">$'+row.delivery_fee+'</small>');
              }
             },          
            { data: "expiry_date_for_book_now" },          
            { data: "status" },        
            {
              render: function (data, type, row, meta) {
                if(row.post_to_bank == 1 && self.is_ecommerce_admin_driven == 1 && self.current_date <= row.expiry_date_for_book_now){
                  var $post_to_bank_bt = ' <a style="color: white !important; margin-bottom: 2px;" class="btn btn-primary pending-post-to-bank mb-2" data-tr-number="' +row.transaction_number +'" data-id="' +row.edit +'">Accept Payment</a>';
                }
                else{
                  var $post_to_bank_bt = '';
                }
                if (row.consumer_approval_for_change_request == 0 && (self.current_date <= row.expiry_date_for_book_now || row.expiry_date_for_book_now == '')) {
                  var $modify_bt = '<a style="color: white !important; margin-bottom: 2px;" class="btn btn-success ecommerce-leave-modify mb-2" data-tr-number="' +row.transaction_number +'" data-tr-amount="' +row.last_approve_amount +'" data-tr-tip-amount="'+row.last_approve_tip_amount+'" data-tr-delivery_fee="'+row.delivery_fee+'" data-id="' +row.edit +'" data-tran-status="' + row.status + '"><i class="fa fa-edit" aria-hidden="true"></i> Modify</a>';
                }
                else {
                  var $modify_bt = '';
                }
                return '<div class="d-flex flex-column">' + $modify_bt + $post_to_bank_bt + '</div>'
              },
            } ,
            {
              render: function (data, type, row, meta) {
                if (row.cancelable == 1 && row.is_v1 == 0 && row.status != 'Voided' && row.status != 'Decline By Consumer' && (self.current_date <= row.expiry_date_for_book_now || row.expiry_date_for_book_now == '')) {
                  return (
                    '<a style="color: white !important; margin-bottom: 9px;" class="btn btn-danger ecommerce-leave-comment" data-id="' +
                    row.edit +
                    '"><i class="fa fa-times fa-lg"></i> Void</a>'
                  );
                }
                else {
                  return null;
                }
              },
            },
            {
              render: function (data, type, row, meta) {
                if (row.time_left_for_approval != '') {
                  var [hours, minutes, seconds] = row.time_left_for_approval.split(':');
                  var totalSeconds = (+hours) * 60 * 60 + (+minutes) * 60 + (+seconds);
                  return ('<span class="ec_da_countdown_time" data-seconds="'+totalSeconds+'">'+row.time_left_for_approval+'</span>');
                }else if(row.status == 'Awaiting Consumer Approval'){
                  return 'Request Timeout';
                }else{
                  return '';
                }
              },
            } 
          ],
        });
        
        var self = this;
        $("#transactionTable").width("100%");
        // if(self.view_more == true && self.viewMoreType == 'Awaiting Consumer Approval'){
        //   $("#transactionTable").width("90%");
        // }
        $("#transactionTable").on("page.dt", function () {
          $("html, body").animate({ scrollTop: 0 }, "slow");
          $("th:first-child").focus();
        });
        //Search in the table only after 3 characters are typed
        // Call datatables, and return the API to the variable for use in our code
        // Binds datatables to all elements with a class of datatable
        var dtable = $("#transactionTable").dataTable().api();
  
        // Grab the datatables input box and alter how it is bound to events
        $(".dataTables_filter input")
        .unbind() // Unbind previous default bindings
        .bind("input", function(e) { // Bind our desired behavior
            // If the length is 3 or more characters, or the user pressed ENTER, search
            if(this.value.length >= 3 || e.keyCode == 13) {
                // Call the API search function
                dtable.search(this.value).draw();
            }
            // Ensure we clear the search if they backspace far enough
            if(this.value == "") {
                dtable.search("").draw();
            }
            return;
        });
      },
      countdownTimeUpdate(event) {
        var totalSeconds = event.data("seconds");
        if(totalSeconds > 0){
          var timmerhtml = moment.utc(totalSeconds*1000).format('HH:mm:ss');
        }else{
          event.removeClass( "ec_da_countdown_time" );
          var timmerhtml = 'Request Timeout'
        }
        var totalSeconds = totalSeconds - 1;
        event.data('seconds',totalSeconds);
        event.html(timmerhtml)
      },
      ecommerceDashboardLeaveComment() {
        var self = this;
        $(document).on("click", ".pending-post-to-bank", function (e) {
          //open the modal
          self.transaction_id = $(e.currentTarget).attr("data-id");
          self.sendToBankModalMsg = 'Do you want to post this transaction to the bank?';
          self.$bvModal.show("post-to-bank-model");
        });
        $(document).on("click", ".ecommerce-leave-comment", function (e) {
          //open the modal
          self.comment = "";
          self.$bvModal.show("comment-modal");
          //set transaction id to a hidden field for future use
          self.transaction_id = $(e.currentTarget).attr("data-id");
          
        });
        $(document).on("click", ".ecommerce-leave-modify", function (e) {
          //open the modal
          self.updated_amount = "";
          self.selectedReason = "";
          self.selectedReasonType = "";
          self.reasonSelectOther = false;
          self.reasonComment = '';
          self.resetModificationDAta();

          self.transaction_id = $(e.currentTarget).attr("data-id");
          self.modalTitle = "Modify Payment Amount for " + $(e.currentTarget).attr("data-tr-number");
          self.tr_amount = parseFloat($(e.currentTarget).attr("data-tr-amount")).toFixed(2);
          self.tip_amount = parseFloat($(e.currentTarget).attr("data-tr-tip-amount")).toFixed(2);
          self.delivery_fee = parseFloat($(e.currentTarget).attr("data-tr-delivery_fee")).toFixed(2);
          self.modification_status = $(e.currentTarget).attr("data-tran-status");
          self.$bvModal.show("modification-modal");
        });
      },
      cancelTransaction(bvModalEvt) {
        var self = this;
        if (self.comment == "") {
          self.showMsg = true;
          bvModalEvt.preventDefault();
        } else {
          self.showMsg = false;
          var request = {
            transaction_id: self.transaction_id,
            comment: self.comment,
            transaction_place: "Web",
          };
          self.loading = true;
          transaction_api
            .cancelTransaction(request)
            .then((response) => {
              self.loading = false;
              if ((response.code == 200)) {
                success(response.message);
                self.hideModal('comment-modal');
                self.hideModal('view-more-transactions-modal');
                self.comment == "";
                if(self.is_preloaded_dashboard == true){
                  self.loadTransactionModificationDT();
                }
              } else {
                error(response.message);
              }
            })
            .catch((err) => {
              self.loading = false;
              error(err);
            });
        }
      },
      ecommerceModifyLeaveComment(e){
        var self = this;
        //open the modal
        self.updated_amount = "";
        self.selectedReason = "";
        self.selectedReasonType = "";
        self.reasonSelectOther = false;
        self.reasonComment = '';
        self.resetModificationDAta();

        self.transaction_id = $(e.currentTarget).attr("data-id");
        self.modalTitle = "Modify Payment Amount for " + $(e.currentTarget).attr("data-tr-number");
        self.tr_amount = parseFloat($(e.currentTarget).attr("data-tr-amount")).toFixed(2);
        self.tip_amount = parseFloat($(e.currentTarget).attr("data-tr-tip-amount")).toFixed(2);
        self.delivery_fee = parseFloat($(e.currentTarget).attr("data-tr-delivery_fee")).toFixed(2);
        self.modification_status = $(e.currentTarget).attr("data-tran-status");
        self.$bvModal.show("modification-modal");
        //set transaction id to a hidden field for future use
      },
      resetModificationDAta(){
        var self = this;
        self.UpAmtMsg = false;
        self.reasonSelectError = false;
        self.reasonTypeSelectError = false;
        self.reasonCommentError = false;
        self.UpAmtErrorMsg = '';
        self.reasonSelectErrorMsg = '';
        self.reasonTypeSelectErrorMsg = '';
        self.reasonCommentErrorMsg = '';

        self.modifyTransactionMessage = '';
        self.ModifyButton = ''
      },
      selecteReasonType(event) {
        var self = this;
        self.modalloading = true;
        self.reasonTypeSelectError = "";
        self.selectedReason = "";
        self.getModificationReason(event.target.value);
      },
      selecteReason(val) {
        if(val.id == 'custom'){
          this.reasonSelectOther = true;
        }else{
          this.reasonSelectOther = false;
        }
      },
      getModificationReason(type) {
        var self = this;
        let request = {
          reason_type: type
        };
        self.modificationReasonList = [{'id': 'custom', 'reason' : 'Custom', 'need_approval' : 0}];
        user_api
          .getModificationReason(request)
          .then((response) => {
            self.modalloading = false;
            if ((response.code == 200)) {
              self.modificationReasonList = response.data.concat([{'id': 'custom', 'reason' : 'Custom', 'need_approval' : 0}]);
            } else {
              error(response.message);
            }
          })
          .catch((err) => {
            self.modalloading = false;
            error(err.response.data.message);
          });
      },
      modifyTransactionStep1(UpAmtEvent){
        var self = this;
        self.resetModificationDAta();
        if(self.updated_amount != ''){
          self.updated_amount = parseFloat(self.updated_amount).toFixed(2);
        }
        if(self.selectedReasonType == ''){
          self.reasonTypeSelectError = true;
          self.reasonTypeSelectErrorMsg = 'Please fill in the required fields.';
          UpAmtEvent.preventDefault();
        }else if(self.selectedReason == ''){
          self.reasonSelectError = true;
          self.reasonSelectErrorMsg = 'Please fill in the required fields.';
          UpAmtEvent.preventDefault();
        }else if(self.updated_amount == ''){
          self.UpAmtMsg = true;
          self.UpAmtErrorMsg = 'Please fill in the required fields.';
          UpAmtEvent.preventDefault();
        }else if(self.selectedReason.id == 'custom' && self.reasonComment == ''){
          self.reasonCommentError = true;
          self.reasonCommentErrorMsg = 'Please fill in the required fields.';
          UpAmtEvent.preventDefault();
        }else {
          self.updated_amount = parseFloat(self.updated_amount);
          self.tr_amount = parseFloat(self.tr_amount);
          if(self.updated_amount <= self.tr_amount && self.selectedReasonType == 'increase'){
            self.updated_amount = parseFloat(self.updated_amount).toFixed(2);
            self.tr_amount = parseFloat(self.tr_amount).toFixed(2);
            self.UpAmtMsg = true;
            self.UpAmtErrorMsg = 'You are selecting price increase reason. So, new amount must be greater than old amount.';
            UpAmtEvent.preventDefault();
          }else if(self.updated_amount >= self.tr_amount && self.selectedReasonType == 'decrease'){
            self.updated_amount = parseFloat(self.updated_amount).toFixed(2);
            self.tr_amount = parseFloat(self.tr_amount).toFixed(2);
            self.UpAmtMsg = true;
            self.UpAmtErrorMsg = 'You are selecting price decrease reason. So, new amount must be less than old amount.';
            UpAmtEvent.preventDefault();
          }else if((self.updated_amount > self.tr_amount ) || self.selectedReason.need_approval == 1){
            self.modifyTransactionMessage = 'This transaction modification requires consumer approval. Do you want to modify this transaction?';
            self.$refs["modification-modal-step-2"].show();
            self.ModifyButton = 'Confirm & Send'
          } else if(self.updated_amount < self.tr_amount){
            self.modifyTransactionMessage = 'Do you want to modify this transaction?';
            self.$refs["modification-modal-step-2"].show();
            self.ModifyButton = 'Confirm'
          } else {
            self.UpAmtMsg = true;
            self.UpAmtErrorMsg = 'Old and new amount not equal.';
            UpAmtEvent.preventDefault();
          }
        }
      },
      modifyTransaction(UpAmtEvent){
        var self = this;
        if(self.updated_amount == ''){
          self.UpAmtMsg = true;
          UpAmtEvent.preventDefault();
        }
        else {
          if(self.advance_search || self.view_more){
            self.modalloading = true;
          }
          else{
            self.loading = true;
          }
          self.UpAmtMsg = false;
          var request = {
            transaction_id: self.transaction_id,
            updated_amount: self.updated_amount,
            reason_id: self.selectedReason.id,
            reason_comment: self.reasonComment,
          };
          self.hideModal('modification-modal-step-2');
          self.hideModal('modification-modal');
          transaction_api
            .merchantTransactionUpdate(request)
            .then((response) => {
              if ((response.code = 200)) {
                success(response.message);
                self.updated_amount = "";
                if(self.advance_search){
                  self.hideModal('view-more-transactions-modal');
                  if(self.is_preloaded_dashboard == true){
                    self.loadTransactionModificationDT();
                  }
                }
                else{
                  self.hideModal('view-more-transactions-modal');
                  self.loadTransactionModificationDT();
                }
              } else {
                error(response.message);
              }
              self.loading = false;
              self.modalloading = false;
            })
            .catch((err) => {
              self.loading = false;
              self.modalloading = false;
              error(err);
            });
        }
      },
      onKeyupUpdatedAmount(val){
        if(this.updated_amount == '.'){
          this.updated_amount = '0' + '.'
        }
      },
      viewModificationHistory(row){
        var self= this;
        self.transactionHistory = [];
        self.transaction_id = row.edit;
        self.historyModalTitle = "Transaction history for " + row.transaction_number;
        self.modifiedTransactionHistory();
      },
      modifiedTransactionHistory(){
        var self = this;
        self.loading = true;
        self.UpAmtMsg = false;
        var request = {
          transaction_id: self.transaction_id,
        };
        transaction_api
          .modifiedTransactionHistory(request)
          .then((response) => {
            if ((response.code = 200)) {
              self.transactionHistory = response.data;
              self.$bvModal.show("modified-tr-history-modal");
              success(response.message);
            } else {
              error(response.message);
            }
            self.loading = false;
          })
          .catch((err) => {
            self.loading = false;
            error(err);
          });
      },
    },
  };
</script>
<style>
  .bg-amber{
  background-color: #E96D17 !important;
  color: #fff!important;
  }
  .expire-box{
  cursor: pointer;
  }
  .no-record-found{
  line-height: 313px;
  }
  td.fitwidth {
    width: 1px;
    white-space: nowrap;
  }

  .dashboard-btn{
    border: 0;
    padding: 7px 13px;
    border-radius: 3px;
    font-size: 12px;
    width: 31%;
  }
  .btn-modify{
    background-color: #218838;
    color: #fff;
  }
  .btn-void{
    background-color: #dc3545;
    color: #fff;
  }
  .btn-accept-payment{
    background-color: #007bff;
    color: #fff;
  }
  .viewmore-btn{
    border: 0;
    padding: 7px 13px;
    border-radius: 3px;
    font-size: 12px;
    width: 31%;
  }
  .btn-modify-viewmore{
    background-color: #218838;
    color: #fff;
    margin-right: 7px;
  }
  .btn-void-viewmore{
    background-color: #dc3545;
    color: #fff;
    margin-right: 7px;
  }
  .btn-accept-payment-viewmore{
    background-color: #007bff;
    color: #fff;
  }
  .ecommerce-leave-modify{
    width: 160px !important;
    text-align: center;
  }
  .ecommerce-leave-comment{
    width: 160px !important;
    text-align: center;
  }
  .accept-payment{
    width: 160px !important;
    text-align: center;
  }
  #view-more-transactions-modal___BV_modal_content_{
    width: 976px !important;
    position: absolute;
    top: 50%;
    left: 45%;
  }
</style>