<template>
  <div>
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6"></div>
        </div>
      </div>
    </section>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card card-success">
              <div class="card-header">
                <h3 class="card-title">Regional Managers</h3>
              </div>
              <div class="card-body">
                <!-- /.card-header -->
                <div class="card-body">
                  <table
                    id="regionalManagerTable"
                    class="table"
                    style="width: 100%; white-space: normal"
                  >
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Phone Number</th>
                        <th>Address</th>
                        <th>Customers Since</th>
                        <th>Status</th>
                        <th>Action(s)</th>
                      </tr>
                    </thead>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- RM Modal Start -->
    <b-modal
      id="rm-modal"
      ref="modal"
      :header-text-variant="headerTextVariant"
      title="Change Consumer Status"
      @show="resetModal"
      @hidden="resetModal"
      ok-title="Save"
      ok-variant="success"
      cancel-variant="outline-secondary"
      @ok="handleOk"
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
    >
      <form ref="form" @submit.stop.prevent="save" class="needs-validation">
        <div class="row">
          <div class="col-md-12">
            <label for="user_type">
              Status
              <span class="red">*</span>
            </label>
            <select
              class="form-control"
              id="user_status"
              name="user_status"
              v-model="status"
              v-validate="'required'"
            >
              <option
                v-for="(status, index) in statusList"
                :key="index"
                :value="status.id"
              >
                {{ status.status }}
              </option>
            </select>
            <span v-show="errors.has('user_status')" class="text-danger">{{
              errors.first("user_status")
            }}</span>
          </div>
        </div>
      </form>
    </b-modal>
    <!-- Consumer Modal End -->
  </div>
</template>
<script>
import api from "@/api/user.js";
import Constants from "@/common/constant.js";
export default {
  data() {
    return {
      userId: "",
      allRMmodel: {},
      statusList: [],
      headerTextVariant: "light",
      status: "",
      constants: Constants,
    };
  },
  created() {
    this.editRegionalManager();
  },
  methods: {
    resetModal() {
      var self = this;
      self.status = "";
    },
    handleOk(bvModalEvt) {
      var self = this;
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      self.changeStatus();
    },
    loadDT: function () {
      var self = this;
      $("#regionalManagerTable").DataTable({
        pagingType: "simple_numbers",
        processing: true,
        serverSide: true,
        destroy: true,
        columnDefs: [
          { orderable: false, targets: [6] },
          { className: "dt-left", targets: [0, 1, 2, 3, 4, 5] },
          { className: "dt-center", targets: [6] },
        ],
        order: [[4, "desc"]],
        orderClasses: false,
        language: {
          processing:
            '<i class="fa fa-spinner fa-spin fa-3x fa-fw"></i><span class="sr-only">Loading...</span> ',
          emptyTable: "No Regional Manager Record Available.",
          search: "_INPUT_",
          searchPlaceholder: "Search records",
          oPaginate: {
            sNext: '<i class="fas fa-angle-double-right"></i>',
            sPrevious: '<i class="fas fa-angle-double-left"></i>',
          },
          sLengthMenu:
            "<label class='label_dropdown_dt'>Per page</label> _MENU_",
        },
        ajax: {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
          url:
            process.env.VUE_APP_API_URL +
            "/merchantadmin/getallregionalmanagers",
          type: "POST",
          dataType: "json",
          dataSrc: function (result) {
            self.allRMmodel = result.data;
            return result.data;
          },
          error: function (request) {
            if (
              request.responseJSON.code == 401 &&
              request.responseJSON.data != null
            ) {
              localStorage.setItem("token", request.responseJSON.data);
              self.loadDT();
            }else{
              error(Constants.datatable_error);
              $('#itpersonnelTable_processing').hide();
            }
          },
        },
        columns: [
          { data: "name" },
          { data: "email" },
          { data: "phone" },
          {
            render: function (data, type, full, meta) {
              return (
                full.address +
                ", " +
                full.city +
                ", " +
                full.state +
                ", " +
                full.zipcode
              );
            },
          },
          { data: "created_at" },
          {
            render: function (data, type, full, meta) {
              return full.status_name;
            },
          },
          {
            render: function (data, type, full, meta) {
              return (
                '<b-button data-user-id="' +
                full.edit +
                '" class="editRegionalManager custom-edit-btn" title="Edit Consumer Status" variant="outline-success"><i class="nav-icon fas fa-edit"></i></b-button>'
              );
            },
          },
        ],
      });
      $("#regionalManagerTable").on("page.dt", function () {
        $("html, body").animate({ scrollTop: 0 }, "slow");
        $("th:first-child").focus();
      });
    },
    getUserStatus() {
      var self = this;
      api
        .getUserStatus()
        .then((response) => {
          if ((response.code = 200)) {
            self.statusList = response.data;
          } else {
            error(response.message);
          }
        })
        .catch((err) => {
          error(err);
        });
    },
    editRegionalManager() {
      var self = this;
      $(document).on("click", ".editRegionalManager", function (e) {
        self.userId = $(e.currentTarget).attr("data-user-id");
        var userdetails = self.allRMmodel.find((p) => p.edit == self.userId);
        self.$bvModal.show("rm-modal");
        self.status = userdetails.status;
      });
    },
    changeStatus() {
      var self = this;
      var request = {
        id: self.userId,
        status: self.status,
      };
      api
        .changeManagerStatus(request)
        .then((response) => {
          if ((response.code = 200)) {
            success(response.message);
            self.$bvModal.hide("rm-modal");
            self.loadDT();
          } else {
            error(response.message);
          }
        })
        .catch((err) => {
          error(err);
        });
    },
  },
  beforeDestroy(){
    $(document).off('click', '.editRegionalManager');
  },
  mounted() {
    var self = this;
    setTimeout(function () {
      self.loadDT();
    }, 1000);
    self.getUserStatus();
    document.title = "CanPay - Regional Managers";
  },
};
</script>
