<template>
  <div>
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6"></div>
        </div>
      </div>
    </section>
    <div class="hold-transition sidebar-mini">
      <section class="content">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12">
              <b-button
                variant="outline-success"
                style="margin-top: -48px"
                @click="openModal('add')"
              >
                <i class="fas fa-user-plus"></i> Add Accountant
              </b-button>
              <b-button
                style="margin-left: 50px; margin-top: -48px;"
                class="btn btn-info mr-3"
                @click="showPermission()"
              >Show Permissions</b-button> 
              <div class="card card-success">
                <div class="card-header">
                  <h3 class="card-title">Accountants</h3>
                  <b-button
                    class="btn-danger export-api-btn"
                    @click="reloadDatatable"
                    v-if="showReloadBtn"
                  >
                    <i class="fas fa-redo"></i> Reload
                  </b-button>
                </div>
                <!-- /.card-header -->
                <div class="card-body">
                  <table
                    id="AccountantTable"
                    class="table"
                    style="width: 100%; white-space: normal"
                  >
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Manager ID</th>
                        <th>Created On</th>
                        <th>Status</th>
                        <th>Action(s)</th>
                      </tr>
                    </thead>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <!-- SM Modal Start -->
    <b-modal
      id="sm-modal"
      ref="modal"
      :header-text-variant="headerTextVariant"
      :title="modalTitle"
      @show="resetModal"
      @hidden="resetModal"
      ok-title="Save"
      ok-variant="success"
      cancel-variant="outline-secondary"
      @ok="handleOk"
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
    >
      <form ref="form" @submit.stop.prevent="save" class="needs-validation">
        <div class="row">
          <div class="col-md-6">
            <label for="first_name">
              First Name
              <span class="red">*</span>
            </label>
            <input
              :id="'first_name' + randomNoGenerator()"
              name="first_name"
              v-validate="'required|alpha'"
              type="text"
              v-model="AccountantDetails.first_name"
              class="form-control"
              autocomplete="dontdoit"
            />
            <span v-show="errors.has('first_name')" class="text-danger">{{
              errors.first("first_name")
            }}</span>
          </div>

          <div class="col-md-6">
            <label for="last_name">
              Last Name
              <span class="red">*</span>
            </label>
            <input
              :id="'last_name' + randomNoGenerator()"
              name="last_name"
              v-validate="'required|alpha'"
              type="text"
              v-model="AccountantDetails.last_name"
              class="form-control"
              autocomplete="dontdoit"
            />
            <span v-show="errors.has('last_name')" class="text-danger">{{
              errors.first("last_name")
            }}</span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <label for="email">
              Email Address
              <span class="red">*</span>
            </label>
            <input
              id="email"
              name="email"
              v-validate="'required|email'"
              type="text"
              v-model="AccountantDetails.email"
              class="form-control"
            />
            <span v-show="errors.has('email')" class="text-danger">{{
              errors.first("email")
            }}</span>
          </div>
          <div class="col-md-6">
            <label for="store_id">
              Store
              <span class="red">*</span>
            </label>
            <multiselect
              id="store_id"
              name="store_id"
              v-model="store_ids"
              :options="options"
              :multiple="true"
              track-by="retailer"
              :custom-label="customLabel"
              placeholder="Select Stores"
              selectLabel
              deselectLabel
              :close-on-select="false"
              :clear-on-select="false"
              group-values="storesIndividual"
              group-label="selectAll"
              :group-select="true"
            ></multiselect>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <label for="user_type">
              Status
              <span class="red">*</span>
            </label>
            <select
              class="form-control"
              id="user_status"
              name="user_status"
              v-model="AccountantDetails.status"
              v-validate="'required'"
            >
              <option
                v-for="(status, index) in statusList"
                :key="index"
                :value="status.id"
              >
                {{ status.status }}
              </option>
            </select>
            <span v-show="errors.has('user_status')" class="text-danger">{{
              errors.first("user_status")
            }}</span>
          </div>
          <div class="col-md-6">
            <input
              type="checkbox"
              id="receive_daily_transaction_email"
              name="receive_daily_transaction_email"
              class="checkbox-modal-class"
              v-model="AccountantDetails.receive_daily_transaction_email"
            />
            <b class="dailyTransactionEmail"> Receive Daily Transaction Email</b>
          </div>
        </div>
      </form>
    </b-modal>
    <!-- SM Modal End -->

    <!-- View Store Modal Start -->
    <b-modal
      id="view-store-modal"
      ref="view-store-modal"
      :header-text-variant="headerTextVariant"
      title="Accountant"
      hide-footer
    >
      <div class="row odd-row">
        <div class="col-md-4 row-value">
          <label for="name">Accountant Name</label>
        </div>
        <div class="col-md-1 row-value">:</div>
        <div class="col-md-7 row-value">
          <span for="name">{{ Accountant.name }}</span>
        </div>
      </div>
      <div class="row even-row">
        <div class="col-md-4 row-value">
          <label for="access_other_org_info">Email</label>
        </div>
        <div class="col-md-1 row-value">:</div>
        <div class="col-md-7 row-value">
          <span for="name">{{ Accountant.email }}</span>
        </div>
      </div>
      <div class="row odd-row">
        <div class="col-md-4 row-value">
          <label for="access_rights">Store Access</label>
        </div>
        <div class="col-md-1 row-value">:</div>
        <div class="col-md-7 row-value">
          <span v-for="item in Accountant.retailer" :key="item.id">
            <li style="list-style: none">
              <span class="mb-2" :for="item.id">{{ item.retailer }}</span>
            </li>
          </span>
        </div>
      </div>
    </b-modal>
    <!-- View Store Modal End -->
    <!----- MODAL FOR RESET CONFIRMATION---->
    <div>
      <b-modal ref="confirm-modal" hide-footer hide-header id="confirm-modal">
        <div class="color">
          <div class="col-12 text-center">
            <svg
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 80 100.5"
              xml:space="preserve"
              height="80"
              width="100"
              fill="#149240"
            >
              <g>
                <path
                  d="M76.2,56.2h-17v-22h-9.4v-7.9c0-11.2-9.1-20.2-20.2-20.2S9.4,15.2,9.4,26.4v7.9H0v40.5h28.1c1.9,5.3,7,9.1,12.9,9.1h35.1
		C83.8,83.8,90,77.6,90,70S83.8,56.2,76.2,56.2z M12.4,26.4c0-9.5,7.7-17.2,17.2-17.2s17.2,7.7,17.2,17.2v7.9H12.4V26.4z M3,71.8
		V37.3h53.2v19H41.1c-2.2,0-4.3,0.5-6.1,1.4L34.4,54c1-1.1,1.5-2.6,1.5-4.1c0-3.5-2.8-6.3-6.3-6.3s-6.3,2.8-6.3,6.3
		c0,1.5,0.5,3,1.5,4.1l-1.5,9.6l1.5,1.7h3.3c-0.5,1.5-0.8,3-0.8,4.7c0,0.6,0,1.1,0.1,1.7C27.4,71.7,3,71.7,3,71.8z M29.6,62.4h-3.1
		l1.3-8.6l-0.5-1.4c-0.7-0.6-1.1-1.5-1.1-2.5c0-1.8,1.5-3.3,3.3-3.3c1.8,0,3.3,1.5,3.3,3.3c0,0.9-0.4,1.8-1.1,2.5l-0.5,1.3l0.9,5.7
		C31.2,60.3,30.3,61.3,29.6,62.4z M76.2,80.8H41.1c-6,0-10.8-4.8-10.8-10.8s4.8-10.8,10.8-10.8h35.1c6,0,10.8,4.8,10.8,10.8
		S82.2,80.8,76.2,80.8z"
                ></path>
                <path
                  d="M44.9,67.1c-1.6,0-2.9,1.3-2.9,2.9s1.3,2.9,2.9,2.9c1.6,0,2.9-1.3,2.9-2.9S46.5,67.1,44.9,67.1z"
                ></path>
                <path
                  d="M54.1,67.1c-1.6,0-2.9,1.3-2.9,2.9s1.3,2.9,2.9,2.9S57,71.6,57,70S55.7,67.1,54.1,67.1z"
                ></path>
                <circle cx="63.2" cy="70" r="2.9"></circle>
                <path
                  d="M72.3,67.1c-1.6,0-2.9,1.3-2.9,2.9s1.3,2.9,2.9,2.9s2.9-1.3,2.9-2.9S73.9,67.1,72.3,67.1z"
                ></path>
              </g>
            </svg>
          </div>
          <div class="purchaserpower-modal-text">
            <div class="d-block text-center">
              <label class="text-justify text-secondary h4">
                Are you sure ?
              </label>
              <br />
              <label class="text-justify text-secondary text-dark">
                Do you want to reset the password to the default password?
              </label>
            </div>
            <div class="row">
              <div class="col-12 text-center">
                <button
                  @click="hideModal('confirm-modal')"
                  class="btn btn-secondary btn-md center-block mr-2"
                >
                  <label class="forgetpassword-ok-label">Cancel</label>
                </button>
                <button
                  @click="resetPasswordConfirm()"
                  class="btn btn-success btn-md center-block ml-2"
                >
                  <label class="forgetpassword-ok-label">Confirm</label>
                </button>
              </div>
            </div>
          </div>
        </div>
      </b-modal>
    </div>

    <!-- SM Modal Start -->
    <b-modal
      id="update-user-role-modal"
      ref="modal"
      :header-text-variant="headerTextVariant"
      title="Update User Role"
      @show="resetModal"
      @hidden="resetModal"
      ok-title="Save"
      ok-variant="success"
      cancel-variant="outline-secondary"
      @ok="updateRole"
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
    >
      <form ref="form" @submit.stop.prevent="save" class="needs-validation">
        <div class="row">
          <div class="col-md-12">
            <label for="user_type">
              User Role
              <span class="red">*</span>
            </label>
            <select
              class="form-control"
              id="user_role"
              name="user_role"
              v-model="AccountantDetails.role_id"
              v-validate="'required'"
            >
              <option
                v-for="(role, index) in roles"
                :key="index"
                :value="role.role_id"
              >
                {{ role.role_name }}
              </option>
            </select>
            <span v-show="errors.has('user_role')" class="text-danger">{{
              errors.first("user_role")
            }}</span>
          </div>
        </div>
      </form>
    </b-modal>
    <!-- SM Modal End -->
    <role-permission-modal :role_name="constants.role_accountant" ref="RolePermissionModal"></role-permission-modal>
  </div>
</template>
<script>
import moment from "moment";
import api from "@/api/user.js";
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
import Constants from "@/common/constant.js";
import RolePermissionModal from './RolePermissionModal.vue';
export default {
  mixins: [validationMixin],
  components: {
    RolePermissionModal
  },
  data() {
    return {
      user: JSON.parse(localStorage.getItem("store_user")),
      modalTitle: "",
      dob: "",
      headerTextVariant: "light",
      statusList: [],
      AccountantDetails: {},
      Accountant: {},
      allAccountantModel: {},
      userId: null,
      add: true,
      stores: {},
      store_ids: null,
      value: [],
      options: {},
      showReloadBtn: false,
      constants: Constants,
      roles:{}
    };
  },
  created() {
    this.editAccountant();
    this.viewStoreDetails();
    this.resetPassword();
    this.updateUserRole();
    this.fetchRoleList();
  },

  methods: {
    showPermission() {
      var self = this;
      self.$refs.RolePermissionModal.showPermission();
    },
    reloadDatatable() {
      var self = this;
      self.loadDT();
    },
    randomNoGenerator() {
      return Math.floor(100000 + Math.random() * 90000000);
    },
    customLabel(store) {
      return `${store.retailer}`;
    },
    viewStoreDetails() {
      var self = this;
      $(document).on("click", ".viewStoreDetails", function (e) {
        self.Accountant = self.allAccountantModel.find(
          (p) => p.edit == $(e.currentTarget).attr("data-user-id")
        );
        self.$bvModal.show("view-store-modal");
      });
    },

    editAccountant() {
      var self = this;
      $(document).on("click", ".editAccountant", function (e) {
        self.userId = $(e.currentTarget).attr("data-user-id");
        var userdetails = self.allAccountantModel.find(
          (p) => p.edit == self.userId
        );
        userdetails = JSON.parse(JSON.stringify(userdetails));
        self.modalTitle = "Edit Accountant";
        self.add = false;
        self.$bvModal.show("sm-modal");
        self.AccountantDetails = userdetails;
        self.dob = userdetails.date_of_birth;
        self.store_ids = userdetails.retailer;
        self.AccountantDetails.user_id = userdetails.edit;
        self.items = userdetails.retailer;
        self.getStores();
      });
    },

    openModal(type) {
      var self = this;
      self.dob = "";
      if (type == "edit") {
        self.modalTitle = "Edit Accountant";
        self.add = false;
        self.$bvModal.show("sm-modal");
      } else {
        self.add = true;
        self.modalTitle = "Add Accountant";
        self.$bvModal.show("sm-modal");
        self.getStores();
      }
    },

    resetModal() {
      var self = this;
      self.AccountantDetails = {};
      self.store_ids = null;
    },

    handleOk(bvModalEvt) {
      var self = this;
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      self.save();
    },

    save() {
      var self = this;
      // Exit when the form isn't valid
      this.$validator.validateAll().then((result) => {
        if (result) {
          self.AccountantDetails.user_type = "Accountant";
          self.AccountantDetails.store_id = [];
          $.each(self.store_ids, function (key, value) {
            self.AccountantDetails.store_id.push(value.id);
          });
          //call to api to save the details
          if (!self.AccountantDetails.user_id) {
            // Add section
            api
              .addUser(self.AccountantDetails)
              .then((response) => {
                if (response.code == 200) {
                  success(response.message);
                  $("#AccountantTable").DataTable().ajax.reload(null, false);
                  self.$bvModal.hide("sm-modal");
                  self.getStores();
                  self.resetModal();
                } else {
                  error(response.message);
                }
              })
              .catch((err) => {
                error(err.response.data.message);
              });
          } else {
            // Edit Section
            api
              .updateUser(self.AccountantDetails)
              .then((response) => {
                if (response.code == 200) {
                  success(response.message);
                  $("#AccountantTable").DataTable().ajax.reload(null, false);
                  self.$bvModal.hide("sm-modal");
                  self.getStores();
                  self.store_ids = null;
                  self.resetModal();
                } else {
                  error(response.message);
                }
              })
              .catch((err) => {
                error(err.response.data.message);
              });
          }
        }
      });
    },

    getStores() {
      var self = this;
      let request = {
        user_id: self.AccountantDetails.user_id,
      };
      api
        .getStores(request)
        .then((response) => {
          if ((response.code = 200)) {
            self.stores = response.data;
            self.options = [
              {
                selectAll: "Select All Stores",
                storesIndividual: self.stores,
              },
            ];
          } else {
            error(response.message);
          }
        })
        .catch((err) => {
          error(err.response.data.message);
        });
    },
    loadDT: function () {
      var self = this;
      $("#AccountantTable").DataTable({
        pagingType: "simple_numbers",
        processing: true,
        serverSide: true,
        destroy: true,
        columnDefs: [
          { orderable: false, targets: [5] },
          { className: "dt-left", targets: [0, 1, 2, 3, 4] },
          { className: "dt-center", targets: [5] },
        ],
        order: [[2, "desc"]],
        orderClasses: false,
        language: {
          processing:
            '<i class="fa fa-spinner fa-spin fa-3x fa-fw"></i><span class="sr-only">Loading...</span> ',
          emptyTable: "No Accountant Available.",
          search: "_INPUT_",
          searchPlaceholder: "Search records",
          oPaginate: {
            sNext: '<i class="fas fa-angle-double-right"></i>',
            sPrevious: '<i class="fas fa-angle-double-left"></i>',
          },
          sLengthMenu:
            "<label class='label_dropdown_dt'>Per page</label> _MENU_",
        },
        ajax: {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
          url: process.env.VUE_APP_API_URL + "/merchantadmin/getallstoreusers",
          type: "POST",
          data: { _token: "{{csrf_token()}}", user_type: "Accountant" },
          dataType: "json",
          dataSrc: function (result) {
            self.showReloadBtn = false;
            self.allAccountantModel = result.data;
            return result.data;
          },
          error: function (request) {
            if (
              request.responseJSON.code == 401 &&
              request.responseJSON.data != null
            ) {
              localStorage.setItem("token", request.responseJSON.data);
              self.loadDT();
            } else {
              error(Constants.datatable_error);
              $("#AccountantTable_processing").hide();
              self.showReloadBtn = true;
            }
          },
        },
        columns: [
          { data: "name" },
          { data: "email" },
          { data: "user_identifier" },
          { data: "created_at" },
          {
            render: function (data, type, full, meta) {
              return full.status_name;
            },
          },
          {
            render: function (data, type, full, meta) {
              var action = '<b-button data-user-id="' +
                full.edit +
                '" class="viewStoreDetails custom-edit-btn" title="View Assigned Stores" variant="outline-success"><i class="nav-icon fas fa-eye"></i>&nbsp;&nbsp;</b-button><b-button data-user-id="' +
                full.edit +
                '" class="editAccountant custom-edit-btn" title="Edit Accountant" variant="outline-success"><i class="nav-icon fas fa-edit"></i>&nbsp;&nbsp;</b-button><b-button data-user-id="' +
                full.edit +
                '" class="resetPassword custom-edit-btn" title="Reset Password" variant="outline-success"><i class="nav-icon fas fa-key"></i>&nbsp;&nbsp;</b-button>';
              if(self.user.role_name == self.constants.role_corporate_parent){
                action+='<b-button data-user-id="' +
                full.edit +
                '" class="updateUserRole custom-edit-btn" title="Update User Role" variant="outline-success"><i class="nav-icon fas fa-user"></i>&nbsp;&nbsp;</b-button>';
              }
              return (
                action
              );
            },
          },
        ],
      });

      $("#AccountantTable").on("page.dt", function () {
        $("html, body").animate({ scrollTop: 0 }, "slow");
        $("th:first-child").focus();
      });

      //Search in the table only after 3 characters are typed
      // Call datatables, and return the API to the variable for use in our code
      // Binds datatables to all elements with a class of datatable
      var dtable = $("#AccountantTable").dataTable().api();

      // Grab the datatables input box and alter how it is bound to events
      $(".dataTables_filter input")
        .unbind() // Unbind previous default bindings
        .bind("input", function (e) {
          // Bind our desired behavior
          // If the length is 3 or more characters, or the user pressed ENTER, search
          if (this.value.length >= 3 || e.keyCode == 13) {
            // Call the API search function
            dtable.search(this.value).draw();
          }
          // Ensure we clear the search if they backspace far enough
          if (this.value == "") {
            dtable.search("").draw();
          }
          return;
        });
    },
    changeStatus() {
      $(document).on("click", ".change-status", function (e) {
        var request = {
          id: $(e.currentTarget).attr("data-id"),
        };
        api
          .changeManagerStatus(request)
          .then((response) => {
            if ((response.code = 200)) {
              success(response.message);
            } else {
              error(response.message);
            }
          })
          .catch((err) => {
            error(err);
          });
      });
    },

    getUserStatus() {
      var self = this;
      api
        .getUserStatus()
        .then((response) => {
          if ((response.code = 200)) {
            self.statusList = response.data;
          } else {
            error(response.message);
          }
        })
        .catch((err) => {
          error(err);
        });
    },
    resetPassword() {
      var self = this;
      console.log("oka");
      $(document).on("click", ".resetPassword", function (e) {
        self.userId = $(e.currentTarget).attr("data-user-id");
        self.$bvModal.show("confirm-modal");
      });
    },
    resetPasswordConfirm() {
      var self = this;
      self.$bvModal.hide("confirm-modal");
      var request = {
        user_id: self.userId,
      };
      api
        .resetPassword(request)
        .then((response) => {
          if ((response.code = 200)) {
            success("Password reset mail to user");
          } else {
            error(response.message);
          }
        })
        .catch((err) => {
          error(err);
        });
    },
        hideModal() {
      this.$bvModal.hide("confirm-modal");
    },
    updateUserRole(){
      var self = this;

      $(document).on("click", ".updateUserRole", function (e) {
        self.userId = $(e.currentTarget).attr("data-user-id");
        self.Accountant = self.allAccountantModel.find(
          (p) => p.edit == $(e.currentTarget).attr("data-user-id")
        );
        self.$bvModal.show("update-user-role-modal");
      });
    },
    fetchRoleList(){
      var self = this;
      var request = {
        role_name: self.constants.role_accountant,
      };
      api
        .fetchRoleList(request)
        .then((response) => {
          self.roles = response.data;
        })
        .catch((err) => {
          error(err);
        });
    },
    updateRole(){
       var self = this;
      // Exit when the form isn't valid
      self.$validator.validateAll().then((result) => {
        if (result) {
          var request = {
            role_id: self.AccountantDetails.role_id,
            user_id: self.userId,
            current_role: self.constants.role_accountant,
          };
          api
            .updateUserRole(request)
            .then((response) => {
              if (response.code == 200) {
                success(response.message);
                self.$bvModal.hide("update-user-role-modal");
                self.resetModal();
                self.loadDT();
              } else {
                error(response.message);
              }
            })
            .catch((err) => {
              error(err.response.data.message);
            });
        }
      });
    }
  },
  beforeDestroy(){
    $(document).off('click', '.editAccountant');
  },
  mounted() {
    var self = this;
    setTimeout(function () {
      self.loadDT();
    }, 1000);
    self.getUserStatus();
    self.getStores();
    document.title = "CanPay - Accountant";
  },
};
</script>
<style lang="scss">
#confirm-modal___BV_modal_body_ {
  background-color: #ffffff;
  border-radius: 12px;
  margin: 10px;
}
</style>