import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { loadProgressBar } from 'axios-progress-bar';
loadProgressBar();
import BootstrapVue from 'bootstrap-vue';
// Install BootstrapVue
Vue.use(BootstrapVue);
Vue.use(require('vue-moment'));
import 'axios-progress-bar/dist/nprogress.css'
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

import VueGtm from '@gtm-support/vue2-gtm';
Vue.use(VueGtm, {
    id: 'GTM-WNN2QRN', // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
    queryParams: {
        // Add URL query string when loading gtm.js with GTM ID (required when using custom environments)
    },
    defer: false, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
    compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
    nonce: '2726c7f26c', // Will add `nonce` to the script tag
    enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
    debug: true, // Whether or not display console logs debugs (optional)
    loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
    vueRouter: router, // Pass the router instance to automatically sync with router (optional)
});

Vue.directive('numeric', {
    bind(el) {
        el.addEventListener('input', (event) => {
            const inputValue = event.target.value;
            event.target.value = inputValue.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
        });
    }
});
Vue.directive('integer', {
    bind(el) {
        el.addEventListener('input', () => {
            let value = el.value;
            value = value.replace(/[^0-9]/g, ''); // Remove all non-numeric characters
            el.value = value;
            el.dispatchEvent(new Event('input')); // Dispatch input event to update v-model
        });
    }
});

import Multiselect from 'vue-multiselect';
Vue.component('multiselect', Multiselect);
import 'vue-multiselect/dist/vue-multiselect.min.css'

import VeeValidate from "vee-validate";
Vue.use(VeeValidate, { fieldsBagName: 'veeFields' });

import { Validator } from 'vee-validate';

//time count down for transaction modification
import VueCountdownTimer from 'vuejs-countdown-timer';
Vue.use(VueCountdownTimer);
// Sweatalert
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2);
const dictionary = {
    en: {
        attributes: {
            first_name: 'First Name',
            middle_name: 'Middle Name',
            last_name: 'Last Name',
            email: 'Email Address',
            username: 'Username',
            phone: 'Phone No.',
            contact_person: 'Conatct Person',
            store_id: 'Store',
            user_type: 'User Type',
            ssn_number: 'SSN',
            password: 'Password',
            confirm_password: 'Confirm Password',
            pin: 'Pin',
            confirm_pin: 'Confirm Pin',
            user_status: 'User Status',
            api_secret: 'API Secret',
            campaign_id: 'Campain ID',
        }
    }
};

Validator.localize(dictionary);
window.axios = require('axios');
Vue.config.productionTip = false
axios.defaults.baseURL = process.env.VUE_APP_API_URL; //base url for all apis
axios.defaults.headers.common["Authorization"] = localStorage.getItem("token");

// axios interceptor code for error handling
axios.interceptors.response.use((response) => {
    return response;
}, (error) => {
    const originalRequest = error.config;
    if (error.response.data.code == 401 && error.response.data.data != null && !originalRequest._retry) {
        originalRequest._retry = true;
        axios.defaults.headers.common["Authorization"] = error.response.data.data;
        originalRequest.headers["Authorization"] = error.response.data.data;
        localStorage.setItem("token", error.response.data.data);
        return axios(originalRequest);
    } else if (error.response.data.code == 401) { // Written for JWT algorithm change. If a user logged in with HS256 algo and did not logged out. After deploying the RS256 algo of JWT the user will face a Inavlid JWT token issue. Hence logging out the user.
        localStorage.removeItem("token");
        localStorage.removeItem("store_user");
        localStorage.clear();
        delete axios.defaults.headers.common["Authorization"];
        router.push("/login").catch((err) => {});
    }
    if (error.request.responseType === 'blob' && error.response.data instanceof Blob) {
        alert('Export error');
    } else {
        alert(error.response.data.message);
    }
    return Promise.reject(error);
});
const reward_wheel_axios = axios.create({
    baseURL: process.env.VUE_APP_REWARD_WHEEL_API_URL
})

let isInternetConnected = true
const pendingRequests = new Map()

reward_wheel_axios.interceptors.request.use(
    config => {
        if (!isInternetConnected) {
            const cancelToken = new axios.CancelToken(cancel => {
                config.cancel = cancel
            })
            config.cancelToken = cancelToken
            throw new axios.Cancel('Please check your internet connection and try again')
        }
        if (isInternetConnected) {
            addPendingRequest(config)
        }
        return config
    },
    error => {
        console.error('Request cancelled:', error.message)
        return Promise.reject(error)
    }
)

reward_wheel_axios.interceptors.response.use(
    response => {
        removePendingRequest(response.config)
        return response
    },
    error => {
        removePendingRequest(error.config || {})
        return Promise.reject(error)
    }
)

function addPendingRequest(config) {
    if (isInternetConnected) {
        config.cancelToken = new axios.CancelToken(cancel => {
            pendingRequests.set(getRequestKey(config), cancel)
        })
    }
}

function removePendingRequest(config) {
    pendingRequests.delete(getRequestKey(config))
}

function getRequestKey(config) {
    return config.url + JSON.stringify(config.params)
}

export {
    reward_wheel_axios
}
reward_wheel_axios.interceptors.response.use((response) => {
    return response;
}, (error) => {
    const originalRequest = error.config;
    if (error.response.data.code == 401 && error.response.data.data != null && !originalRequest._retry) {
        originalRequest._retry = true;
        reward_wheel_axios.defaults.headers.common["Authorization"] = 'Bearer ' + error.response.data.data;
        originalRequest.headers["Authorization"] = 'Bearer ' + error.response.data.data;
        localStorage.setItem("token", error.response.data.data);
        return reward_wheel_axios(originalRequest);
    }
    if (error.request.responseType === 'blob' && error.response.data instanceof Blob) {
        // Convert the Blob into text
        const reader = new FileReader();
        reader.onloadend = () => {
            const responseText = reader.result;
            try {
                const responseData = JSON.parse(responseText); // Assuming the response is in JSON format

                if (responseData.code === 401 && responseData.data != null && !originalRequest._retry) {
                    localStorage.setItem("token", responseData.data);
                }
            } catch (e) {
                console.error("Error parsing response data:", e);
            }
        };
        // Read the Blob as text
        reader.readAsText(error.response.data);
        alert('Export error');
    }
    return Promise.reject(error);
});


(function() {
    const idleDurationSecs = 600;
    let idleTimeout;

    const resetIdleTimeout = function() {
        if (idleTimeout) clearTimeout(idleTimeout);
        idleTimeout = setTimeout(() => router.push("/login"), idleDurationSecs * 1000);
    };

    // Key events for reset time
    resetIdleTimeout();
    window.onmousemove = resetIdleTimeout;
    window.onkeypress = resetIdleTimeout;
    window.click = resetIdleTimeout;
    window.onclick = resetIdleTimeout;
    window.touchstart = resetIdleTimeout;
    window.onfocus = resetIdleTimeout;
    window.onchange = resetIdleTimeout;
    window.onmouseover = resetIdleTimeout;
    window.onmouseout = resetIdleTimeout;
    window.onmousemove = resetIdleTimeout;
    window.onmousedown = resetIdleTimeout;
    window.onmouseup = resetIdleTimeout;
    window.onkeypress = resetIdleTimeout;
    window.onkeydown = resetIdleTimeout;
    window.onkeyup = resetIdleTimeout;
    window.onsubmit = resetIdleTimeout;
    window.onreset = resetIdleTimeout;
    window.onselect = resetIdleTimeout;
    window.onscroll = resetIdleTimeout;

})();
new Vue({
    router,
    render: function(h) { return h(App) }
}).$mount('#app')