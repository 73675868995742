<template>
  <!-- Main Sidebar Container -->
  <aside class="main-sidebar sidebar-dark-primary elevation-4">
    <!-- Brand Logo -->
    <a href="#" class="brand-link">
      <img
        src="../../assets/images/logo-white.png"
        alt="CanPay Logo"
        class="brand-image"
        style="opacity: 0.8; margin-left: 0"
      />
      <span class="brand-text font-weight-light">Merchant Admin</span>
    </a>

    <!-- Sidebar -->
    <div class="sidebar scroll-style-3">
      <!-- Sidebar user panel (optional) -->
      <div class="user-panel mt-3 pb-3 mb-3 d-flex">
        <div class="image">
          <b-avatar
            variant="success"
            :text="name_initials"
            class="mr-3"
          ></b-avatar>
        </div>
        <div class="info">
          <a
            href="#collapseOne"
            class="d-block accordion-toggle collapsed"
            data-toggle="collapse"
          >
            {{ full_name }} &nbsp;
            <i class="fa fa-caret-down"></i>
          </a>
          <div id="collapseOne" class="collapse">
            <ul
              class="nav nav-pills nav-sidebar flex-column"
              data-widget="treeview"
              role="menu"
              data-accordion="false"
            >
              <li class="nav-item" style="margin-left: -17px; margin-top: 10px">
                <router-link to="/userProfile" class="nav-link">
                  <i class="nav-icon fas fa-edit"></i>
                  <p>Profile</p>
                </router-link>
              </li>
              <li
                v-if="user.role_name === constants.role_regional_manager"
                class="nav-item"
                style="margin-left: -17px; margin-top: 10px"
              >
                <router-link to="/bankDetails" class="nav-link">
                  <i class="nav-icon fas fa-university"></i>
                  <p>Bank Details</p>
                </router-link>
              </li>
              <li class="nav-item" style="margin-left: -17px; margin-top: 10px">
                <router-link to="/changePassword" class="nav-link">
                  <i class="nav-icon fas fa-unlock-alt"></i>
                  <p>Change Password</p>
                </router-link>
              </li>
              <li
                class="nav-item"
                style="margin-left: -17px; margin-top: 10px; cursor: pointer"
              >
                <a class="nav-link" @click="logout">
                  <i class="nav-icon fas fa-power-off"></i>
                  <p>Logout</p>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <!-- Sidebar Menu -->
      <nav class="mt-2">
        <ul
          class="nav nav-pills nav-sidebar flex-column"
          data-widget="treeview"
          role="menu"
          data-accordion="false"
          v-if="is_sposor_store"
        >
          <li class="nav-item">
            <router-link to="/sponsordashboard" class="nav-link">
              <i class="nav-icon fas fa-columns"></i>
              <p>Sponsor Dashboard</p>
            </router-link>
          </li>
        </ul>
        <ul
          class="nav nav-pills nav-sidebar flex-column"
          data-widget="treeview"
          role="menu"
          data-accordion="false"
          v-else-if="user.role_name == constants.role_new_brand_manager"
        >
          <li class="nav-item">
            <router-link to="/campaignlist" class="nav-link">
              <i class="nav-icon fas fa-columns"></i>
              <p>Campaign List</p>
            </router-link>
          </li>
        </ul>
        <ul
          class="nav nav-pills nav-sidebar flex-column"
          data-widget="treeview"
          role="menu"
          data-accordion="false"
          v-if="user.role_name == constants.role_new_brand_manager"
        >
          <li class="nav-item">
            <router-link to="/campaignassignhistory" class="nav-link">
              <i class="nav-icon fas fa-columns"></i>
              <p>Campaign Points History</p>
            </router-link>
          </li>
        </ul>
        <ul
          class="nav nav-pills nav-sidebar flex-column"
          data-widget="treeview"
          role="menu"
          data-accordion="false"
          v-else
        >
          <!-- Add icons to the links using the .nav-icon class
          with font-awesome or any other icon font library-->
          <li class="nav-item" v-if="user.role_name !== constants.role_new_remotepay_manager">
            <router-link to="/dashboard" class="nav-link">
              <i class="nav-icon fas fa-columns"></i>
              <p>Dashboard</p>
            </router-link>
          </li>
          <li class="nav-item" v-if="user.role_name !== constants.role_accountant && user.role_name !== constants.role_store_manager">
            <router-link to="/ecommercedashboard" class="nav-link">
              <i class="nav-icon fas fa-columns"></i>
              <p>RemotePay Management</p>
            </router-link>
          </li>
          <li class="nav-item" v-if="user.role_name !== constants.role_new_remotepay_manager">
            <router-link to="/releases" class="nav-link">
              <i class="nav-icon fas fa-hashtag"></i>
              <p>Releases</p>
            </router-link>
          </li>
          <li class="nav-item has-treeview"  v-if="user.role_name !== constants.role_accountant && user.role_name !== constants.role_store_manager && user.role_name !== constants.role_new_remotepay_manager">
            <a href="javascript:void(0);" class="nav-link">
              <i class="nav-icon fas fa-users"></i>
              <p>
                Users
                <i class="right fas fa-angle-left"></i>
              </p>
            </a>
            <ul class="nav nav-treeview">
              <li class="nav-item">
                <router-link to="/regionalManager" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Regional Managers</p>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/remotepayManager" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Remotepay Manager</p>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/storeManagers" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Store Managers</p>
                </router-link>
              </li>
                <li class="nav-item" >
                <router-link to="/accountant" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Accountant</p>
                </router-link>
              </li>

              <li class="nav-item">
                <router-link to="/deviceManagers" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Device Manager</p>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/employees" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Employees</p>
                </router-link>
              </li>
            </ul>
          </li>
          <li class="nav-item"  v-if="user.role_name !== constants.role_accountant && user.role_name !== constants.role_store_manager  && user.role_name !== constants.role_new_regional_manager && user.role_name !== constants.role_new_remotepay_manager">
            <router-link to="/stores" class="nav-link">
              <i class="nav-icon fas fa-building"></i>
              <p>Stores</p>
            </router-link>
          </li>
          <li class="nav-item"  v-if="user.role_name !== constants.role_accountant && user.role_name !== constants.role_store_manager && user.role_name !== constants.role_new_remotepay_manager">
            <router-link to="/emailaddressDailyTransaction" class="nav-link">
              <i class="nav-icon fas fa-at"></i>
              <p>Email addresses for Daily</p> <br/>
              <p>Transaction Activity</p>
            </router-link>
          </li>
          <li class="nav-item" v-if="user.role_name == constants.role_corporate_parent">
            <router-link to="/pointsbackmaster" class="nav-link">
              <p>
                <svg style="margin-left: 3px;" fill="none" viewBox="0 0 65 65" height="20" width="22" xmlns="http://www.w3.org/2000/svg">
                  <path d="M52.3781 38.6548C55.171 32.7591 54.028 27.1216 53.7045 25.6262C53.3378 23.9371 52.4105 19.9456 49.0353 16.363C47.4277 14.6796 45.5185 13.3112 43.4063 12.3286C40.5595 11.0053 38.1333 10.7471 36.8069 10.618C31.3397 10.08 26.8215 11.6723 24.2982 12.8127C25.5267 11.6384 26.931 10.662 28.4606 9.91866C29.8123 9.2741 31.2451 8.8145 32.72 8.55232C35.135 8.09188 37.611 8.04458 40.0419 8.41246C41.1634 8.58459 45.8434 9.39149 50.5126 13.0064C57.3169 18.2673 60.1098 25.5724 58.5246 33.9748C56.1092 46.8313 43.7514 54.3623 31.2211 51.0487C27.5332 50.0696 23.6512 47.7565 21.9258 45.4972C22.0121 45.4972 22.1092 45.4757 22.1631 45.508C22.5944 45.7877 23.015 46.089 23.4571 46.3687C30.4663 50.7582 37.648 50.7905 44.7866 46.8636C45.6905 46.3652 46.5493 45.7892 47.3531 45.1422C50.4479 42.6462 51.8605 39.7414 52.3781 38.6548Z" fill="white"/>
                  <path d="M59.1177 39.4294C59.0422 39.8812 58.9128 40.5267 58.7079 41.2906C57.2198 46.756 53.823 50.2095 52.3673 51.6511C47.0403 56.9336 40.8398 58.4613 38.0038 59.1283C32.17 60.4947 27.4577 59.9137 25.7647 59.6447C19.6721 58.6765 15.4881 56.2235 14.2049 55.4166C11.5869 53.7766 9.24045 51.7404 7.2496 49.3811C4.92444 46.6367 3.10287 43.5046 1.86869 40.1287C1.55597 39.268 0.369802 35.8898 0.0678669 31.2636C-0.212501 26.8526 0.456069 23.5605 0.617819 22.7966C1.14519 20.3012 1.9971 17.8851 3.15191 15.6099C3.75578 14.448 6.82905 8.76745 13.6226 4.52857C15.1969 3.53878 19.3162 1.21493 25.15 0.33273C26.692 0.0960419 39.9879 -1.73292 49.5097 6.58346C53.9201 10.435 56.5189 15.5884 56.9286 16.4168C57.5595 17.6964 58.107 19.0153 58.5677 20.3652C58.4167 20.1608 57.2953 18.59 57.2629 18.547C53.1221 12.4254 48.3127 9.54207 48.3127 9.54207C46.4942 8.47618 44.5389 7.66184 42.5005 7.12139C36.0197 5.3785 30.5633 6.92773 29.2693 7.31504C21.6778 9.5851 17.1811 15.6207 16.9223 15.9757C12.3394 22.2802 12.4364 28.8645 12.5227 30.6934C12.8138 36.8473 15.5097 41.1938 16.739 42.9474C16.836 43.0658 16.9762 43.2487 17.1487 43.4638C17.2781 43.636 17.4075 43.8081 17.5369 43.9803C20.7504 48.1976 25.0098 51.1347 29.7437 52.5979C33.2715 53.6742 36.9993 53.9313 40.642 53.3495C44.2847 52.7677 47.7458 51.3624 50.7605 49.2412C53.4132 47.3692 55.02 45.4219 56.012 44.2169C57.3276 42.6247 58.2873 41.0539 58.6 40.4299C58.6432 40.3546 59.0961 39.4294 59.1177 39.4294Z" fill="#ECB800"/>
                  <path d="M24.449 39.5263C18.7554 39.5263 15.9086 35.7715 15.9086 30.0157C15.9086 24.0662 18.9064 20.4836 24.7725 20.4836C27.037 20.4836 28.8594 21.0215 30.2613 22.1619C31.5768 23.3346 32.3101 24.9269 32.4934 26.9495H30.2073C29.3878 26.9495 28.7947 26.5729 28.4389 25.8306C27.8134 24.5073 26.5841 23.8295 24.7833 23.8295C21.2679 23.8295 19.7043 26.304 19.7043 30.0264C19.7043 33.6413 21.2032 36.1911 24.6647 36.1911C27.037 36.1911 28.3957 34.8893 28.8055 32.8452H32.4826C32.1483 37.1056 29.1398 39.5263 24.449 39.5263Z" fill="white"/>
                  <path d="M44.1072 20.5266H37.6479C36.5588 20.5266 35.6961 21.4733 35.6961 22.5599V39.4617H39.5782V32.716H44.3875C48.1941 32.716 50.2321 30.3491 50.2321 26.6159C50.2321 22.6783 48.1402 20.5266 44.1072 20.5266ZM43.6651 29.2948H39.8801V23.8079H43.8592C45.6708 23.8079 46.5981 24.7224 46.5981 26.5621C46.6089 28.4018 45.6384 29.3271 43.6651 29.2948Z" fill="#ECB800"/>
                </svg>
                Merchant Points <br> Program 
              </p>
              <p class="beta-badge">Beta</p>
            </router-link>
          </li>
          <li class="nav-item"  v-if="show_void_transaction_menu && user.role_name !== constants.role_accountant && user.role_name !== constants.role_new_remotepay_manager">
            <router-link to="/voidtransactions" class="nav-link">
              <i class="nav-icon fa fa-ban"></i>
              <p>Void Transactions</p>
            </router-link>
          </li>

          <li class="nav-item has-treeview" v-if="user.role_name !== constants.role_accountant && user.role_name !== constants.role_store_manager && user.role_name !== constants.role_new_remotepay_manager">
            <a href="javascript:void(0);" class="nav-link">
              <i class="nav-icon fa fa-history"></i>
              <p>
                POS Transaction History
                <i class="right fas fa-angle-left"></i>
              </p>
            </a>
            <ul class="nav nav-treeview">
              <li class="nav-item">
                <router-link
                  to="/transactions"
                  class="nav-link"
                >
                  <i class="far fa-circle nav-icon"></i>
                  <span>POS</span>
                </router-link>
              </li>
            </ul>
          </li>

          <li class="nav-item has-treeview" v-if="user.role_name !== constants.role_accountant && user.role_name !== constants.role_store_manager">
            <a href="javascript:void(0);" class="nav-link">
              <i class="nav-icon fas fa-table"></i>
              <p>
                RemotePay
                <i class="right fas fa-angle-left"></i>
              </p>
            </a>
            <ul class="nav nav-treeview">
              <li class="nav-item"  v-if="user.role_name !== constants.role_accountant && user.role_name !== constants.role_store_manager  && user.role_name !== constants.role_new_regional_manager && user.role_name !== constants.role_new_remotepay_manager">
                <router-link to="/storeapikeys" class="nav-link">
                  <i class="nav-icon fas fa-key"></i>
                  <p>Store API Keys</p>
                </router-link>
              </li>
              <li class="nav-item"  v-if="user.role_name !== constants.role_accountant && user.role_name !== constants.role_store_manager">
                <router-link to="/remotepaytransactionshistory" class="nav-link">
                  <i class="nav-icon fas fa-history"></i>
                  <p>RemotePay</p> <br/>
                  <p>Transaction History</p>
                </router-link>
              </li>
              <li class="nav-item has-treeview"  v-if="user.role_name !== constants.role_new_remotepay_manager">
              <a href="javascript:void(0);" class="nav-link">
                <i class="nav-icon fas fa-table"></i>
                <p>RemotePay <i class="right fas fa-angle-left"></i></p> <br/>
                <p>Holiday List and Timings</p>
              </a>
              <ul class="nav nav-treeview">
                <li class="nav-item">
                  <router-link
                    to="/storeTimingHoliday"
                    class="nav-link"
                  >
                    <i class="far fa-circle nav-icon"></i>
                    <span>Store Timing & Holidays</span>
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link
                    to="/holidayTemplateMaster"
                    class="nav-link"
                  >
                    <i class="far fa-circle nav-icon"></i>
                    <span>Template Master</span>
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link
                    to="/holidayMaster"
                    class="nav-link"
                  >
                    <i class="far fa-circle nav-icon"></i>
                    <span>Holiday Master</span>
                  </router-link>
                </li>
              </ul>
              </li>
            </ul>
          </li>

          <li class="nav-item has-treeview" v-if="user.role_name !== constants.role_new_remotepay_manager">
            <a href="javascript:void(0);" class="nav-link">
              <i class="nav-icon fas fa-table"></i>
              <p>
                Reports
                <i class="right fas fa-angle-left"></i>
              </p>
            </a>
            <ul class="nav nav-treeview">
              <li class="nav-item">
                <router-link
                  to="/merchantLocationTransactions"
                  class="nav-link"
                >
                  <i class="far fa-circle nav-icon"></i>
                  <span class="f-13">Transaction Report</span>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  to="/discountedfeereport"
                  class="nav-link"
                >
                  <i class="far fa-circle nav-icon"></i>
                  <span class="f-13">Reduced Transaction Fees Report</span>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/settlementReport" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <span class="f-13">Settlement/Fees Report</span>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/pointsbackreport" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <span class="f-13">Points Program Report</span>
                </router-link>
              </li>
              <!-- <li class="nav-item" v-if="user.role_name == constants.role_corporate_parent">
                <router-link to="/pointsbackprogramreportold" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <span class="f-13">Merchant Points Program Report</span>
                </router-link>
              </li> -->
              <li class="nav-item">
                <router-link
                  to="/historicalSettlementReport"
                  class="nav-link"
                >
                  <i class="far fa-circle nav-icon"></i>
                  <span class="f-13">Historical Settlement/Fees Report</span>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/tipReportv1" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <span class="f-13">Tip Report V1 (Shifts)</span>
                </router-link>
              </li>
              <!-- <li class="nav-item">
                <router-link to="/tipReportv2" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <span class="f-13">Tip Report V2 (Employee)</span>
                </router-link>
              </li> -->
            </ul>
          </li>
        </ul>
        
      </nav>
      <!-- /.sidebar-menu -->
    </div>
    <!-- /.sidebar -->
  </aside>
</template>
<script>
import * as AdminLte from "../../../public/js/adminlte.js";
import Constants from "@/common/constant.js";
export default {
  data() {
    return {
      user: JSON.parse(localStorage.getItem("store_user")),
      full_name: null,
      name_initials: null,
      constants: Constants,
      show_void_transaction_menu:localStorage.getItem('disable_store_count') > 0 ? true : false,
      is_sposor_store:localStorage.getItem('sposor_store_count') > 0 ? true : false,
    };
  },
  methods: {
    logout() {
      localStorage.removeItem("token");
      localStorage.removeItem("store_user");
      localStorage.clear();
      success("Logged out successfully.");
      this.$router.push("/login");
    },
  },
  mounted() {
    var self = this;
    if(self.user.role_name == Constants.role_corporate_parent || self.user.role_name == Constants.role_new_brand_manager){
      self.full_name = self.user.contact_person_first_name + " " + self.user.contact_person_last_name;
    }else{
      if (self.user.middle_name) {
        self.full_name =
        self.user.first_name +
        " " +
        self.user.middle_name +
        " " +
        self.user.last_name;
      } else {
        self.full_name = self.user.first_name + " " + self.user.last_name;
      }
    }
    
    let matches = self.full_name.match(/\b(\w)/g);
    self.name_initials = matches.join("");
    $('[data-widget="treeview"]').each(function () {
      AdminLte.Treeview._jQueryInterface.call($(this), "init");
    });

    console.log("disable count"+self.show_void_transaction_menu);
  },
};
</script>
<style>
.nav-sidebar .menu-open>.nav-treeview {
    display: inline-block !important;
    width: -webkit-fill-available !important;
}
.beta-badge {
  position: absolute;
  right: 2rem;
  top: 0.7rem;
  margin-top: -10px !important;
  background-color: #149240;
  color: #ffffff;
  padding: 1px 3px;
  font-size: 0.75rem;
  border-radius: 3px;
  font-weight: bold;
  animation: blink 1s infinite;
}
.router-link-active .beta-badge {
  background-color: #343a40 !important;
}
</style>
