<template>
  <div>
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6"></div>
        </div>
      </div>
    </section>
    <div class="hold-transition sidebar-mini">
      <section class="content">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12">
              <b-button
                variant="outline-success"
                style="margin-top: -48px"
                @click="openModal('add')"
                v-if="allEmailAddressModel.length < 5"
              >
                <i class="fas fa-user-plus"></i> Add Email Address
              </b-button>
              <div class="card card-success">
                <div class="card-header">
                  <h3 class="card-title">Email Address for Daily Transaction Activity</h3>
                  <b-button
                  class="btn-danger export-api-btn"
                  @click="reloadDatatable"
                  v-if="showReloadBtn"
                  >
                    <i class="fas fa-redo"></i> Reload
                  </b-button>
                </div>
                <!-- /.card-header -->
                <div class="card-body">
                  <table
                    id="emailAddressTable"
                    class="table"
                    style="width: 100%; white-space: normal"
                  >
                    <thead>
                      <tr>
                        <th width="50%">Email</th>
                        <th width="25%">Created On</th>
                        <th width="25%">Action(s)</th>
                      </tr>
                    </thead>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <!-- SM Modal Start -->
    <b-modal
      id="sm-modal"
      ref="modal"
      :header-text-variant="headerTextVariant"
      :title="modalTitle"
      @show="resetModal"
      @hidden="resetModal"
      ok-title="Save"
      ok-variant="success"
      cancel-variant="outline-secondary"
      @ok="handleOk"
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
    >
      <form ref="form" @submit.stop.prevent="save" class="needs-validation">
        <div class="row">
          <div class="col-md-12">
            <label for="email">
              Email Address
              <span class="red">*</span>
            </label>
            <input
              id="email"
              name="email"
              v-validate="'required|email'"
              type="text"
              v-model="emailAddressDetails.email"
              class="form-control"
            />
            <span v-show="errors.has('email')" class="text-danger">{{
              errors.first("email")
            }}</span>
          </div>
        </div>
      </form>
    </b-modal>
    <!-- SM Modal End -->
    
  </div>
</template>
<script>
import moment from "moment";
import api from "@/api/user.js";
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
import Constants from "@/common/constant.js";
export default {
  mixins: [validationMixin],
  data() {
    return {
      modalTitle: "",
      dob: "",
      headerTextVariant: "light",
      emailAddressDetails: {},
      allEmailAddressModel: {},
      userId: null,
      add: true,
      value: [],
      showReloadBtn:false,
      constants: Constants,
    };
  },
  created() {
    this.editEmailAddress();
    this.deleteEmailAddress();
  },
  beforeDestroy(){
    $(document).off("click", ".editEmailAddress");
    $(document).off("click", ".deleteEmailAddress");
  },
  methods: {
    reloadDatatable(){
      var self = this;
      self.loadDT();
    },
    randomNoGenerator(){
      return Math.floor(100000 + Math.random() * 90000000);
    },
    customLabel(store) {
      return `${store.retailer}`;
    },
    editEmailAddress() {
      var self = this;
      $(document).on("click", ".editEmailAddress", function (e) {
        self.userId = $(e.currentTarget).attr("data-user-id");
        var userdetails = self.allEmailAddressModel.find(
          (p) => p.edit == self.userId
        );
        userdetails = JSON.parse(JSON.stringify(userdetails));
        self.modalTitle = "Edit Email Address";
        self.add = false;
        self.$bvModal.show("sm-modal");
        self.emailAddressDetails = userdetails;
        self.emailAddressDetails.id = userdetails.edit;
      });
    },
    deleteEmailAddress() {
      var self = this;
      $(document).on("click", ".deleteEmailAddress", function (e) {
        self.userId = $(e.currentTarget).attr("data-user-id");
        var userdetails = self.allEmailAddressModel.find(
          (p) => p.edit == self.userId
        );
        self.emailAddressDetails.id = userdetails.edit;
        //API to Delete Email Address
        var result = confirm("Are you sure to delete?");
        if(result){
            api
            .deleteEmailAddress(self.emailAddressDetails)
            .then((response) => {
              if (response.code == 200) {
                success(response.message);
                $("#emailAddressTable").DataTable().ajax.reload(null, false);
              } else {
                error(response.message);
              }
            })
            .catch((err) => {
              error(err.response.data.message);
            });
        }
      });
    },
    openModal(type) {
      var self = this;
      if (type == "edit") {
        self.modalTitle = "Edit Email Address";
        self.add = false;
        self.$bvModal.show("sm-modal");
      } else {
        self.add = true;
        self.modalTitle = "Add Email Address";
        self.$bvModal.show("sm-modal");
      }
    },
    resetModal() {
      var self = this;
      self.emailAddressDetails = {};
    },
    handleOk(bvModalEvt) {
      var self = this;
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      self.save();
    },
    save() {
      var self = this;
      // Exit when the form isn't valid
      this.$validator.validateAll().then((result) => {
        if (result) {
          //call to api to save the details
          if (!self.emailAddressDetails.id) {
            // Add section
            api
              .addEmailAddress(self.emailAddressDetails)
              .then((response) => {
                if (response.code == 200) {
                  success(response.message);
                  $("#emailAddressTable").DataTable().ajax.reload(null, false);
                  self.$bvModal.hide("sm-modal");
                  self.resetModal();
                } else {
                  error(response.message);
                }
              })
              .catch((err) => {
                error(err.response.data.message);
              });
          } else {
            // Edit Section
            api
              .updateEmailAddress(self.emailAddressDetails)
              .then((response) => {
                if (response.code == 200) {
                  success(response.message);
                  $("#emailAddressTable").DataTable().ajax.reload(null, false);
                  self.$bvModal.hide("sm-modal");
                  self.resetModal();
                } else {
                  error(response.message);
                }
              })
              .catch((err) => {
                error(err.response.data.message);
              });
          }
        }
      });
    },
    loadDT: function () {
      var self = this;
      $("#emailAddressTable").DataTable({
        pagingType: "simple_numbers",
        processing: true,
        serverSide: true,
        destroy: true,
        columnDefs: [
          { orderable: false, targets: [2] },
          { className: "dt-left", targets: [0, 1] },
          { className: "dt-center", targets: [2] },
        ],
        order: [[1, "desc"]],
        orderClasses: false,
        language: {
          processing:
            '<i class="fa fa-spinner fa-spin fa-3x fa-fw"></i><span class="sr-only">Loading...</span> ',
          emptyTable: "No Email Address available.",
          search: "_INPUT_",
          searchPlaceholder: "Search records",
          oPaginate: {
            sNext: '<i class="fas fa-angle-double-right"></i>',
            sPrevious: '<i class="fas fa-angle-double-left"></i>',
          },
          sLengthMenu:
            "<label class='label_dropdown_dt'>Per page</label> _MENU_",
        },
        ajax: {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
          url: process.env.VUE_APP_API_URL + "/merchantadmin/getAllEmailAddressDailyAddress",
          type: "POST",
          data: { _token: "{{csrf_token()}}"},
          dataType: "json",
          dataSrc: function (result) {
            self.showReloadBtn = false;
            self.allEmailAddressModel = result.data;
            return result.data;
          },
          error: function (request) {
            if (
              request.responseJSON.code == 401 &&
              request.responseJSON.data != null
            ) {
              localStorage.setItem("token", request.responseJSON.data);
              self.loadDT();
            }else{
              error(Constants.datatable_error);
              $('#emailAddressTable_processing').hide();
              self.showReloadBtn = true;
            }
          },
        },
        columns: [
          { data: "email" },
          { data: "created_at" },
          {
            render: function (data, type, full, meta) {
              return (
                '<b-button data-user-id="' +
                full.edit +
                '" class="editEmailAddress custom-edit-btn" title="Edit Email Address" variant="outline-success"><i class="nav-icon fas fa-edit"></i></b-button>&nbsp;<b-button data-user-id="' +
                full.edit +
                '" class="deleteEmailAddress custom-edit-btn" title="Delete Email Address" variant="outline-success"><i class="nav-icon fas fa-trash-alt"></i>&nbsp;&nbsp;</b-button>'
              );
            },
          },
        ],
      });

      $("#emailAddressTable").on("page.dt", function () {
        $("html, body").animate({ scrollTop: 0 }, "slow");
        $("th:first-child").focus();
      });
    },
  },
  mounted() {
    var self = this;
    setTimeout(function () {
      self.loadDT();
    }, 1000);
    document.title = "CanPay - Email Address for Daily Transaction";
  },
};
</script>

