<template>
  <div>
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6"></div>
        </div>
      </div>
    </section>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card card-success">
              <div class="card-header">
                <h3 class="card-title">Merchant Points Program Report</h3>
              </div>
              <div class="loading" v-if="loading">
                <hour-glass></hour-glass>
              </div>
              <div class="card-body">
                <div class="card" style="box-shadow: none;">
                  <div class="card-body">
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group">
                                <label for="start-date">
                                    Start Date
                                    <span class="red">*</span>
                                </label>
                                <input
                                  class="start-date form-control"
                                  placeholder="Start Date"
                                  id="start-date"
                                  onkeydown="return false"
                                  autocomplete="off"
                                  @input="dateDiff"
                                />
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                <label for="end-date">
                                    End Date
                                    <span class="red">*</span>
                                </label>
                                <input
                                  class="end-date form-control"
                                  placeholder="End Date"
                                  id="end-date"
                                  onkeydown="return false"
                                  autocomplete="off"
                                  @input="dateDiff"
                                />
                                </div>
                            </div>
                        </div>
                        <small class="text-red" id="error-code" style="display:none;">The report period should be of maximum 7 days.</small>
                    </div>
                </div>
                  </div>
                  <div class="card-footer">
                    <button
                      type="button"
                      class="btn btn-success"
                      id="generateBtn"
                      @click="generateExportReport(false)"
                    >
                      Generate
                    </button>
                    <button
                      type="button"
                      @click="generateExportReport(true)"
                      class="btn btn-danger ml-10"
                      id="generateExportBtn"
                    >
                      Generate & Export<i
                        class="fa fa-download ml-10"
                        aria-hidden="true"
                      ></i>
                    </button>
                  </div>
  
  
                <div class="card-body">
                  <div class="row">
                    <div class="col-12">
                      <b-table-simple
                        responsive
                        show-empty
                        bordered
                        sticky-header="800px"
                      >
                        <b-thead head-variant="light">
                          <b-tr>
                            <b-th class="text-center">Store ID</b-th>
                            <b-th class="text-center">Retailer</b-th>
                            <b-th class="text-center">Transaction Number</b-th>
                            <b-th class="text-center">Transaction Amount ($)</b-th>
                            <b-th class="text-center">Transaction Time (UTC)</b-th>
                            <b-th class="text-center">Points Program Name</b-th>
                            <b-th class="text-center">Points Program Percentage</b-th>
                            <b-th class="text-center">Points Program Amount ($)</b-th>
                          </b-tr>
                        </b-thead>
                        <b-tbody v-if="report.length > 0">
                          <b-tr v-for="(row, index) in report" :key="index">
                            <b-td class="text-center text-gray">{{
                              row.store_id
                            }}</b-td>
                            <b-td class="text-center text-gray">{{
                              row.retailer
                            }}</b-td>
                            <b-td class="text-center text-gray">{{
                              row.transaction_number
                            }}</b-td>
                            <b-td class="text-center text-gray">{{
                              row.transaction_amount
                            }}</b-td>
                            <b-td class="text-center text-gray">{{
                              row.transaction_time
                            }}</b-td>
                            <b-td class="text-center text-gray">{{
                              row.cashback_program_name
                            }}</b-td>
                            <b-td class="text-center text-gray">{{
                              row.cashback_price_value
                            }}</b-td>
                            <b-td class="text-center text-gray">{{
                              row.cashback_amount_earned
                            }}</b-td>
                          </b-tr>
                        </b-tbody>
                        <b-tbody v-else>
                            <b-tr>
                              <b-td colspan="9" class="text-center text-gray">There are no records to show</b-td>
                            </b-tr>
                        </b-tbody>
                      </b-table-simple>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import cashback from "@/api/cashback.js";
import moment from "moment";
import { validationMixin } from "vuelidate";
import { HourGlass } from "vue-loading-spinner";
export default {
  mixins: [validationMixin],
  data() {
    return {
      report: [],
      loading: false
    };
  },
  components: {
    HourGlass,
  },
  mounted() {
    var self = this;
    $("#start-date,#end-date").datepicker({
      format: "mm/dd/yyyy",
      autoclose: true,
      todayHighlight: true,
    }).on('changeDate', function (ev) {
        self.dateDiff();
    });
    $("#start-date , #end-date").datepicker("setDate", new Date());
  },
  methods: {
    dateDiff(){
      var self = this;
      if ($("#start-date").val() != "") {
        var from_date = moment($("#start-date").val()).format("YYYY-MM-DD");
      } else {
        var from_date = "";
      }
      if ($("#end-date").val() != "") {
        var to_date = moment($("#end-date").val()).format("YYYY-MM-DD");
      } else {
        var to_date = "";
      }
      if(from_date!='' && to_date!=''){
        //calculate the date Difference
        var date1 = new Date(from_date);
        var date2 = new Date(to_date);
        // To calculate the time difference of two dates
        var Difference_In_Time = date2.getTime() - date1.getTime();

        // To calculate the no. of days between two dates
        var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

        if(Difference_In_Days > 7){
          $("#generateBtn,#generateExportBtn").prop('disabled', true);
          $("#error-code").fadeIn(500);
        }else{
          $("#generateBtn,#generateExportBtn").prop('disabled', false);
          $("#error-code").fadeOut(500);
        }
      }
    },
    // API call to generate the storewise monthly sales report
    generateExportReport(reportExport) {
      var self = this;
      if (
        moment($("#start-date").val()).format("YYYY-MM-DD") >
        moment($().val()).format("YYYY-MM-DD")
      ) {
        error("Start date cannot be from future.");
        return false;
      }
      if (
        moment($("#end-date").val()).format("YYYY-MM-DD") >
        moment($().val()).format("YYYY-MM-DD")
      ) {
        error("End date cannot be from future.");
        return false;
      }
      var request = {
        from_date: moment($("#start-date").val()).format("YYYY-MM-DD"),
        to_date: moment($("#end-date").val()).format("YYYY-MM-DD"),
      };
      if(request.from_date > request.to_date){
        error("To Date cannot be greater than From date");
        return false;
      }
      self.generateReport(request, reportExport);
    },

    generateReport(request, reportExport) {
      var self = this;
      self.report = [];
      self.loading = true;
      cashback
        .generatePointsProgramReport(request)
        .then(function (response) {
          self.report = response.data;
          if (reportExport) {
            request.is_export = true;
            self.exportReport(request);
          } else {
            self.loading = false;
          }
        })
        .catch(function (error) {
          self.loading = false;
        });
    },

    // exports the report
    exportReport(request) {
      var self = this;
      cashback
        .exportPointsProgramReport(request)
        .then(function (response) {
          var FileSaver = require("file-saver");
          var blob = new Blob([response], {
            type: "application/xlsx",
          });
          FileSaver.saveAs(
            blob,
            moment().format("MM/DD/YYYY") + "_merchant_points_program_report.xlsx"
          );
          self.loading = false;
        })
        .catch(function (error) {
          // error(error);
          self.loading = false;
        });
    },
  },
};
</script>
